import { useEffect, useState } from "react";
import firebase from "../firebase";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom"; 

const Categories = () => {
  const history = useHistory();
  const { cat } = useParams();
  const [noproducts, setNoproduct] = useState(false)
  const [l, setL] = useState(false)

  const [products, setData] = useState([]);
  const uppercase = (str) =>{
    return str.toUpperCase()
  }

  
  const getProductByArray =async (arr = []) => {
    if (arr.length > 0){
      await firebase.firestore()
      .collection("products") 
      .where('slug', "in", arr)
      .get()
      .then(async (snapshots) => {
        let data = [];
        snapshots.forEach(d => {
          var ds = d.data();
          ds.id = d.id;
          data.push(ds);
        })
        console.log('data', data);
        if (data.length > 0){
          localStorage.setItem(`cat_${cat}`,JSON.stringify(data));  
          setData(data);
        }else{
          setNoproduct(true);
        }
      }).catch((err) => {
        console.log("err", err)
        setNoproduct(true);
      });
    }else{
      console.log("err", "la recherche n'est effectué sur rien")
      setNoproduct(true);
      setTimeout(() => {
        history.push("/");
      }, 2000);
    }
    
  }

  const getProduct = async () => {
    setL(true);

    const c = JSON.parse(localStorage.getItem(`cat_${cat}`));
    if (c) {
      if (c?.length > 0){
        setData(c);
      } 
    }
    if (cat === 'all'){
      await firebase.firestore()
      .collection("products")
      .orderBy('order', 'asc')
      .get()
      .then(async (snapshots) => {
        let data = [];
        snapshots.forEach(d => {
          var ds = d.data();
          ds.id = d.id; 
          data.push(ds);
        })
        if (data.length > 0){
          localStorage.setItem(`cat_${cat}`,JSON.stringify(data));  
          setData(data);
        }
        setL(false);
      }).catch(() => {
        setL(false);
      })
    }else{
      await firebase.firestore()
      .collection("products") 
      .where('slug', "==", cat)
      .get()
      .then(async (snapshots) => {
        let data = [];
        snapshots.forEach(d => {
          var ds = d.data();
          ds.id = d.id; 
          data.push(ds);
        })
        if (data.length > 0){
          localStorage.setItem(`cat_${cat}`,JSON.stringify(data));  
          setData(data);
          setL(false);
        }else{
          // peut etre un menu principale: 

          await firebase.firestore()
          .collection("menu") 
          .where('slug', "==", cat)
          .get()
          .then( (deuxshots) => {
            let dg = [];
            deuxshots.forEach( async ds => { 
              ds.data().sub.forEach(s => {
                dg.push(s.slug);
              })
            }) 
            getProductByArray(dg)
            setL(false);
          }).catch((error) => { 
            setNoproduct(true);
            setL(false);
          });
        }
      })
      .catch((error) => {
        console.log("error", error); 
        alert("Une erreur s'est produite lors du chargement des produits")
        setNoproduct(true);
        setL(false);
      });
    }
    
  };


  useEffect(()=> {
    getProduct()
  }, []);


  const getMinPrice = (p) =>  { 
    let min = Number(p?.price);
    if (p?.pricePer){
      p?.pricePer?.forEach((e)=>{
        if (e?.price < min){
          min = e?.price;
        }
      })
    }

    if (p?.packs?.length > 0){
      p?.packs.forEach((a, i)=>{
        if (a?.price < min){
          min = a?.price;
        }
      })
    } 
    return min;
  }

  // const gent = (i, dd, im, h, l, p1, p2, p3, title) => {

  //   let p = { 
  //     config: {
  //       h:h,
  //       l:l
  //     },
  //     description: dd,
  //     id:`${i}`,
  //     image: im,
  //     order: i,
  //     price:`${p1*100}`,
  //     title: `${h} x ${l}`,
  //     pricePer: [
  //       {
  //         nbr:1,
  //         price:p1*100
  //       },
  //       {
  //         nbr:5,
  //         price:p2*100
  //       },
  //       {
  //         nbr:10,
  //         price:p3*100
  //       },
  //     ]
  //   }
  //   return p
  // }

  // const testt = () => {
  //   let im = "https://www.cewe.fr/cdn/images/qn/np/QnNpMkdaOTlKRFl4ZHk4Z3BlYUlFN0c5bGVsTTdZNnFqWHhnaWl1akQ2S090akMyeFVwQU9aaEFoV1FCQzJHMG9tY2xaaUFjcml0VXptTkx1YnlVQXU5QjM5NXNFNjdxNDVhTHlOc1lUa0k9/impression-directe-alu.jpg";
  //   let dd = "Grâce à la surface mate de l'alu, dites \"au revoir\" aux reflets ! Très design, le tableau photo sur alu à impression directe habillera avec élégance n'importe quel intérieur pour une déco personnalisée unique. Idéal pour les photos en noir et blanc.";
    

    
  //   let pre = [
  //     gent(0,dd,'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdC9m42MVY2jQcsNNiv_VvVYCdMfD4CA2zouzBJuPVuqFM6jGL4u2dTh9BYddEIMiQr6I&usqp=CAU', 15,20,39,35,31, ''),
  //     gent(1,dd,im, 20,30,49,45,41, ''),
  //     gent(2,dd,'https://m.media-amazon.com/images/I/51lsjHoDk+L._AC_UF1000,1000_QL80_.jpg', 30,40,69,65,61, ''),
  //     gent(3,dd,'https://photoservice.cloud/languagesportal/images/ffde7223-d2b7-437a-8efd-2bec7466b00e_panoramic.jpg', 40,50,99,95,91, ''),
  //     // gent(4,dd,'https://www.ouiouitex.com/media/catalog/product/cache/fd46aa3ed6451b966d04bb77d581a719/f/i/file_44.jpg', 90,70,129,119,109, ''),
  //     // gent(5,dd,'https://d2vxclnxwo31nb.cloudfront.net/photopolo/catalog/produits-2017/desktop/PP-Produit-Deco-Toile-1.jpg', 30,30,79,75,65, ''),

  //     // { 
        
  //     //   description: 'Demande par mail',
  //     //   id:"5",
  //     //   image: im,
  //     //   order: 5,
  //     //   price:null,
  //     //   title: "Sur mesure", 
  //     // },
  //   ];

  //   let title = 'Aluminium Luxe'
  //   let order = 5
  //   let das = {
  //     category: 'aluminium Luxe',
  //     slug: 'aluminium-luxe',
  //     title: title,
  //     products : pre
  //   }


    
  //    firebase.firestore()
  //     .collection("products").add(das)
  //     .then( () => {
  //       firebase.firestore()
  //         .collection("menu").add({order: order, slug: das.slug, title: title})
  //         .then( () => {
  //           alert('is ok')
  //         })
  //   })

  // }

 return (
    <> 
    <div
      class="album py-5  " 
    >
      <div class="container">
        <div
          // class="border-bottom"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 class="pb-2">{cat === 'all' ? 'Tout nos services': uppercase(cat).replaceAll('-', ' ')}</h2> 
        </div>
           {noproducts && <p>Aucun produits disponible.</p>}
           {l && <p>Chargement...</p>}

           {/* <a onClick={testt}>TEST</a> */}
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
            {/* <p>Sera afficher ici les produits de catégorie : {cat}</p> */}
            {products.length > 0 && products?.map((product)=>{
              return (
                <>
                {/* {console.log("product?.products", product?.products)} */}
                {product?.products?.sort((a, b) => a.order - b.order).map((gp)=>{ 
                  return (
                    <a href={`/p/${product.id}/${gp.id}`} style={{textDecoration:"none"}}>
                    <div>
                      <img  src={gp.image} style={{maxWidth:"100%"}}/>
                      <div>
                        <p class="text-center p-2" style={{fontWeight:'bold',color:"black", textDecoration:"none"}}>{cat === 'all' && product?.title !== undefined && `${product?.title} `}{gp.title}</p>
                        {gp.price && <p style={{fontWeight: 'bold', color:"rgb(255, 0, 173)", textDecoration:"none"}}><span style={{color:'grey'}}>A partir de </span>{(getMinPrice(gp)/100).toFixed(2)}€</p>}
                      </div>
                    </div>
                    </a>
                  )
                })}
                </>
              )
            })}
      
        </div>
      </div>
    </div>
    </>
  );
}
  export default Categories;
  