/* eslint-disable no-restricted-globals */
import { useParams, useHistory } from "react-router-dom";
import React, { useState, useEffect }  from "react";
import firebase from "../firebase";
import "../App.css"; 
import { useForm } from "react-hook-form";
import Carousel from "react-bootstrap/Carousel";
import Compressor from "compressorjs";
import { toastr } from "react-redux-toastr";

const Edit = ({ navigation }) =>{
    const { id,idp } = useParams();
    const [loadingUser, setLoadingUser] = React.useState(false);
    const [data, setData] = useState(null) 
    const [user, setUser] = React.useState(null);
    const [product, setProduct] = useState(null) 
    const [selectPack, setSelectPack] = useState(null);
    const history = useHistory();
    const [loadingForm1, setLoadingF1] = useState(false)

    const { register, handleSubmit, setValue } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
      });
    

    const getUser = async (uid, cb) => {
      await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then(async (docd) => {
          var user = docd.data();
          user.id = docd.id;
          cb(user);
        })
        .catch((error) => {
          cb(null);
        });
    };
    useEffect(() => {
   
      // check if user is login
      setLoadingUser(true);
      firebase.auth().onAuthStateChanged((u) => {
        if (u) {
          setLoadingUser(true);
          //redirection au compte
          getUser(u.uid, (user) => {
            // console.log("user", user);
            if (user.isAdmin === true){ 
                setUser(user);
                firebase.auth().languageCode = "fr"; 
                setLoadingUser(false); 
            }else{
                history.push("/");
                toastr.error("Vous n'avez pas le droit d'accès a cette page.")
              }
            
          });
        } else {
          setLoadingUser(false);
          history.push("/");
        }
      });
      setTimeout(() => {
        setLoadingUser(false); 
      }, 5000);
    }, []);


 
   


    const getProduct = async () => {
        await firebase.firestore()
          .collection("products") 
          .doc(id)
          .get()
          .then(async (d) => {
            
            var ds = d.data();
            ds.id = d.id;  
            setData(ds); 
            // console.log("ds", ds.products )
            // if (ds?.products > 0){ 
                ds?.products?.forEach(element => {
                    // console.log("element", element)
                    if (element.id === idp){
                        setProduct(element)
                        setValue("maxTitle", ds.title || "")
                        setValue("title", element.title || "");
                        setValue("description", element.description|| "")
                        setValue("configh", element.config.h)
                        setValue("configl", element.config.l)
                        const selectingPack = localStorage.getItem("selectingPack");

                        if (JSON.parse(selectingPack) && element?.packs){
                          let s = JSON.parse(selectingPack); 
                          // console.log("ici", s)
                          // console.log("element", element)
                          element?.packs?.forEach((sz, i) => {
                            if (sz.nbr === s?.pack?.nbr && sz.price === s?.pack?.price){
                              setSelectPack(i)
                            }
                          });
                        }

                    }
                });    
            // }else{
            //     alert("Une erreur s'est produite lors du chargement du produit")
            // }
          })
          .catch((error) => {
            console.log("error", error); 
            getProduct()
            // alert("Une erreur s'est produite lors du chargement du produit")
          });
      };

      useEffect(()=> {
        getProduct()
      }, []);


      const getMinPrice = (p) =>  { 
        let min = Number(p?.price);
        if (p?.pricePer){
          p?.pricePer?.forEach((e)=>{
            if (e?.price < min){
              min = e?.price;
            }
          })
        }
        if (product?.packs?.length > 0){
          product?.packs.forEach((a, i)=>{
            if (a?.price < min){
              min = a?.price;
            }
          })
        } 
        return min;
      }
      function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    const update = async (value) => {
        // formulaire niveau1 vendeur
        if (loadingForm1) {
          return;
        } 
        setLoadingF1(true);
        let md = data;
        md.title = value.maxTitle?.trim()
        
        let index = null;
        let mdq = null
        data?.products?.forEach((pss, ins)=> {
            if (idp === pss.id){
                index = ins
                mdq = pss
            }
        })
        if (index != null && mdq){
            mdq.title = value.title?.trim();
            mdq.description = value.description?.trim();
             
            mdq.config = {
                l: Number(value.configl ),
                h: Number(value.configh )
            }

            let prod = md.products
            prod[index] = mdq
            md.products = prod

            // console.log("d", md)
            
            delete md.id;
            await firebase.firestore()
            .collection("products") 
            .doc(id).update(md).then(() => {
                toastr.success("Mise à jour du produit", "Terminé")
                window.location.reload()
                setLoadingF1(false);
            }).catch((error) => {
                setLoadingF1(false);
                toastr.error("Mise à jour du produit", "Erreur")
                console.log("err", error)
            });

        }else{
            setLoadingF1(false);
            toastr.error("Mise à jour du produit", "Erreur")
        }
        // setLoadingF1(true);
    }
     
    return (
        <div class="container py-5 "> 
        <h2 style={{textAlign:'left'}}>Edition du produit</h2>
        {loadingUser && (<p>Chargement ...</p>)}
        {product && (
          <div class="product-wrap clearfix product-deatil">
            <div class="row">
             
              <div class="col-md-5 col-sm-12 col-xs-12">
                <div class="product-image"> 
                    <Carousel variant="dark"> 
                        <Carousel.Item>
                          <img
                            style={{
                              justifyItems: "center",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              height: 356, 
                            }}
                            className="d-block"
                            src={product.image}
                          />
                        </Carousel.Item> 
                    </Carousel> 
                </div>
                {user?.isAdmin === true && (
                  <div style={{ alignContent:"center"}}>
                    <label>Modifier l'image du produit</label>
                    <input  accept="image/*" onChange={(e) => {
                      
                      if (e.target.files?.length === 1){
                        let file = e.target.files[0]
                        // console.log("file", file)
 
                        var storage = firebase.storage();
                        let ps = makeid(10);
                        // console.log("`images/${ps}.${file.type?.split('/')[1]}`", `images/${ps}.${file.type?.split('/')[1]}`)
                        var pathReference = storage.ref(`images/${ps}.${file.type?.split('/')[1]}`);
                        toastr.warning("Mise à jour de l'image", "En cours")
                        new Compressor(file, {
                          mimeType:'image/jpeg',
                          convertirTypes: ['image/png', 'image/webp', 'image/jpeg'],
                          convertSize: 250000,
                          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                          success: (compressedResult) => {
                            pathReference.put(compressedResult).then((snapshot) => {
                              // console.log('Uploaded a data_url string!', snapshot);
                              pathReference.getDownloadURL().then(async (url) => {
                                // console.log('Lien de téléchargement :', url);
                                // Mettre a jour le lien vers la base de donnée
                                // console.log(data.products)
                                // console.log(idp)

                                let psd = null;
                                let ins = 0;
                                data?.products?.forEach((element, index) => {
                                  if (element.id === idp){
                                    psd = element;
                                    ins = index;
                                  }
                                })
                                
                                psd.image = url;
                                let np = data.products;
                                np[ins] = psd; 
                                // console.log("data n", np)
                                // return;
                                await firebase.firestore()
                                .collection("products") 
                                .doc(id).update({products: np}).then(() => {
                                  toastr.success("Mise à jour de l'image", "Terminé")
                                  window.location.reload()
                                }).catch((error) => {
                                  toastr.error("Mise à jour de l'image", "Erreur")
                          
                                  console.error('Erreur lors de la récupération du lien de téléchargement :', error);
                                });


                              }).catch((error) => {
                                toastr.error("Mise à jour de l'image", "Erreur")
                        
                                console.error('Erreur lors de la récupération du lien de téléchargement :', error);
                              });
                            }).catch(err => {
                              toastr.error("Mise à jour de l'image", "Erreur")
                              console.error("sendfile", err);
                              console.error('Une erreur s\'est produite lors de l\'envoie du fichier');
                              // cb(0)
                            }); 
                  
                          }
                        });
                      
                      
                      }
                      }} type="file"/>
                    </div>
                )

                }
              </div>
              <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
              
              <form
                class="needs-validation"
                novalidate 
                onSubmit={handleSubmit(update, (err) => {
                    console.log(err);
                })}>
                    <div class="row g-3" style={{textAlign:'left'}}>
                    <div class="col-12">
                                <label for="maxTitle" class="form-label">
                                  Titre (groupe)
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="maxTitle"
                                  placeholder="" 
                                  onChange={(e) =>
                                    setValue("maxTitle", e.target.value)
                                  }
                                  {...register("maxTitle")}
                                />
                                <div class="invalid-feedback">
                                Titre (groupe) est requis
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="title" class="form-label">
                                  Titre du produit
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="title"
                                  placeholder="" 
                                  onChange={(e) =>
                                    setValue("title", e.target.value)
                                  }
                                  {...register("title")}
                                />
                                <div class="invalid-feedback">
                               Titre est requis
                                </div>
                              </div>
                              <div class="col-6">
                                <label for="configl" class="form-label">
                                  Largeur photo
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="configl"
                                  placeholder="" 
                                  onChange={(e) =>
                                    setValue("configl", e.target.value)
                                  }
                                  {...register("configl")}
                                />
                                <div class="invalid-feedback">
                                largeur est requis
                                </div>
                              </div>
                              <div class="col-6">
                                <label for="configh" class="form-label">
                                Hauteur photo
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="configh"
                                  placeholder="" 
                                  onChange={(e) =>
                                    setValue("configh", e.target.value)
                                  }
                                  {...register("configh")}
                                />
                                <div class="invalid-feedback">
                                hauteur est requis
                                </div>
                              </div>
                              <div class="col-12">
                              <label  for="description" class="form-label">Description : </label>
                              
                                    <textarea rows={5} style={{width:'100%'}}
                                    id="description" class="form-control"
                                    onChange={(e) =>
                                        setValue("description", e.target.value)
                                      }
                                       placeholder="Entré votre description" {...register("description", { })} />
                               
                              </div>

                             
                    </div>
                    
                    <button
                              class="w-100 btn btn-dark btn-lg mt-4 mb-4"
                              type="submit"
                              disabled={loadingForm1}
                            >
                              {loadingForm1
                                ? "Chargement ...."
                                : "Enregistrer et mettre à jour"}
                            </button>
                    </form>
                 
                {product.price && <h3 class="price-container" style={{fontWeight: 'bold', color:"rgb(255, 0, 173)", textDecoration:"none", textAlign:"left"}}><span style={{color:'grey', fontSize: 12}}>A partir de </span>{(getMinPrice(product) / 100).toFixed(2)} €</h3>} 
                {(product?.pricePer?.length === 0 || product?.pricePer === undefined) && user?.isAdmin === true && (
                  <span style={{padding:"0px", margin:"none", fontSize:10}}><a href="#" onClick={() => {
                    let prix = prompt(`Veuillez entrer votre nouveau prix:`);
                      if (prix !== null) {
                        let ps = Number(prix.replace(",", ".")).toFixed(2)
                        if (confirm(`Voici le nouveau prix: ${ps}€ - vous confirmez?`)){
                          // console.log(Number(Number(prix.replace(",", ".")).toFixed(2))*100)
                          toastr.warning("Mise à jour du prix", "En cours")
                           
                             let np = `${Number(Number(prix.replace(",", ".")).toFixed(2))*100}` 
                             
                            let ssd = data.products;
                            let nps = product
                            nps.price = np;
                            ssd[idp - 1] = nps; 
                            firebase.firestore()
                            .collection("products") 
                            .doc(id).update({products: ssd}).then(() => {
                              toastr.success("Mise à jour du prix", "Success")
                              
                            }).catch(() => {
                              toastr.error("Mise à jour du prix", "Error")
                            })
                        }else{
                          alert("Vous n'avez pas confirmer la saisie.");
                        }
                      } else {
                        alert("Vous avez annulé la saisie.");
                      }
                  }}>Edit</a></span>
                )} 
                <hr />  <br />  

          
         

                <div class="row">
                {product?.pricePer?.length > 0 && (
                  <div class="col-md-6 col-sm-6 col-xs-6 col-6">
                    <p style={{width: 180, textAlign:'center', margin: "auto", marginBottom:10}}><b>UNITÉ</b></p>
                    {product?.pricePer.map((a, i)=>{
                      return (<>
                      <p style={{border: '1px solid lightgrey',backgroundColor: 'lightgrey', padding:5, borderRadius: 15,width: 180, textAlign: 'center', margin: "auto", marginBottom:10 }}>{a?.nbr} à {product?.pricePer[i + 1] ? (product?.pricePer[i + 1]?.nbr - 1) : '99' } - <span><b>{(a?.price/ 100).toFixed(2)}€</b> ttc</span> {user?.isAdmin === true && (
                      <span style={{padding:"0px", margin:"none", fontSize:10}}><a href="#" onClick={() => {
                        let prix = prompt(`Veuillez entrer votre nouveau prix ${a?.nbr} à ${product?.pricePer[i + 1] ? (product?.pricePer[i + 1]?.nbr - 1) : '99' } :`);
                          if (prix !== null) {
                            let ps = Number(prix.replace(",", ".")).toFixed(2)
                            if (confirm(`Voici le nouveau prix pour ${a?.nbr} à ${product?.pricePer[i + 1] ? (product?.pricePer[i + 1]?.nbr - 1) : '99' } photos: ${ps}€ - vous confirmez?`)){
                              // console.log(Number(Number(prix.replace(",", ".")).toFixed(2))*100)
                              toastr.warning("Mise à jour du prix", "En cours")
                               
                                let np = product?.pricePer;
                                let psc = product?.pricePer[i];
                                psc.price = Number(Number(prix.replace(",", ".")).toFixed(2))*100;
                                np[i] = psc;
                                // console.log(np, np)

                                let sdd = data.products;
                                sdd[idp - 1].pricePer = np
                                // console.log("sdd", sdd)
                                // return;
                                firebase.firestore()
                                .collection("products") 
                                .doc(id).update({products: sdd}).then(() => {
                                  toastr.success("Mise à jour du prix", "Success")
                                  let sd = product;
                                  sd.pricePer = np;
                                  setProduct(sd);
                                }).catch(() => {
                                  toastr.error("Mise à jour du prix", "Error")
                                })
                            }else{
                              alert("Vous n'avez pas confirmer la saisie.");
                            }
                          } else {
                            alert("Vous avez annulé la saisie.");
                          }
                      }}>Edit</a></span>)}</p>
                      
                      </>)
                    })}
                  </div>
                )} 
                  {product?.packs?.length > 0 && (
                    <div class="col-md-6 col-sm-6 col-xs-6 col-6">
                    <p style={{width: 180, textAlign:'center', margin: "auto", marginBottom:10}}><b>PACKS</b></p>
                    {product?.packs.map((a, i)=>{ 
                      return (<div style={{display:"flex"}}>
                      <p style={{border: '1px solid orange',cursor: 'pointer', backgroundColor: (i === selectPack ? 'orange' :'white'),  padding:5, borderRadius: 15,width: 180, textAlign: 'center', margin: "auto", marginBottom:10 }} onClick={()=> {
                        // if (i === selectPack){
                        //   setSelectPack(null);
                        // }else{
                        //   setSelectPack(i)
                        // }
                      }}>{a?.nbr} photos <span><b>{(a?.price/ 100).toFixed(2)}€</b> ttc</span></p>
                      {user?.isAdmin === true && (
                        <span style={{padding:"0px", margin:"none", fontSize:10, alignSelf:"center", paddingBottom:10}}><a href="#" onClick={() => {
                          let prix = prompt(`Veuillez entrer votre nouveau prix ${a?.nbr} photos:`);
                            if (prix !== null) {
                              let ps = Number(prix.replace(",", ".")).toFixed(2)
                              if (confirm(`Voici le nouveau prix pour ${a?.nbr} photos: ${ps}€ - vous confirmez?`)){
                                // console.log(Number(Number(prix.replace(",", ".")).toFixed(2))*100)
                                toastr.warning("Mise à jour du prix", "En cours")
                                 
                                  let np = product?.packs;
                                  let psc = product?.packs[i];
                                  psc.price = Number(Number(prix.replace(",", ".")).toFixed(2))*100;
                                  np[i] = psc;
                                  // console.log(np, np)
  
                                  let sdd = data.products;
                                  sdd[idp - 1].packs = np
                                  // console.log("sdd", sdd)
                                  // return;
                                  firebase.firestore()
                                  .collection("products") 
                                  .doc(id).update({products: sdd}).then(() => {
                                    toastr.success("Mise à jour du prix", "Success")
                                    let sd = product;
                                    sd.packs = np;
                                    setProduct(sd);
                                  }).catch(() => {
                                    toastr.error("Mise à jour du prix", "Error")
                                  })
                              }else{
                                alert("Vous n'avez pas confirmer la saisie.");
                              }
                            } else {
                              alert("Vous avez annulé la saisie.");
                            }
                        }}>Edit</a></span>)}
                        </div>)
                    })}
                    <p style={{fontSize:8, paddingTop:10}}>Cliquez sur le pack avant de choisir vos photos</p>
                  </div>
                  )}
                  
                </div> 
          
                          
              </div>
 
            </div>
          </div>
        )} 
      </div>
    )
}
export default Edit;