/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 

import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import moment from 'moment';
import { useForm } from "react-hook-form";
import { API_URL } from "../constant";
import ProgressBar from "react-bootstrap/esm/ProgressBar";

const Order = () => { 

  const { id } = useParams();
  const [sendMode, setSendMode] = React.useState(false); 
  const [tot, setTot] = React.useState(false);
  const [sendt, setSendT] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [myOrder, setMyOrder] = useState(null);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [images, setDl]  = React.useState([]);
  const [imagess, setDls]  = React.useState([]);
  const [imagesL, setDlL]  = React.useState(false);
  const db = firebase.firestore();
  var storage = firebase.storage();
  const history = useHistory();
  const [loadingForm1, setLoadingF1] = useState(false)
   const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  }); 
 
  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const getOrder = async (uid, cb) => { 
    await db
      .collection("orders") 
      .doc(id)
      .get()
      .then(async (doc) => { 
          var order = doc.data();
          order.id = doc.id; 
          if (order.userId === uid){
            history.push("/orders");
            cb(null)
          }else{
            cb(order)
          }
        
      })
      .catch((error) => {
        console.log("getOrder error", error); 
        cb(null)
      });

  }

  function rows(items) {
    const rows = [];
    if (items.length > 0){
      for (let i = 0; i < items.length; i += 12) {
        const rowItems = items.slice(i, i + 12);
        rows.push(rowItems);
      }
    }
    
    return rows
  }

  const dl = (p, cb) => {
    const qte = p.quantity;
    var pathReference = storage.ref(p.resultat);
        pathReference.getDownloadURL()
        .then((url) => {  
          console.log("success dl url", url);
          let im = images;
          im.push({url: url, nb : qte});
          // updloadtest(url);
          setDl(im);
          cb(im);
        }).catch((error) => {
        // Handle any errors
        console.log("error dl url", error )
        });
  }
  // const updloadtest = (link) => {  
  //   console.log("updloadtest");
  //     fetch(`${API_URL}uploadtodrop`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ link: link}),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //           console.log("data", data); 
  //       });

  // }

  useEffect(()=> {
    if (myOrder){ 
      setDlL(true);
      myOrder.products.forEach(async (p) => {
        // console.log("ici", p.resultat);
        dl(p, (m) => {
          // console.log("ici", m.length , myOrder.products.length);
          if (m.length === myOrder.products.length){

          let ims = [];
          m.forEach(d => {
            ims.push({original:d.url, loading:'lazy' });
            setDls(ims);
          })
          setDlL(false);
          }
        })
      })
    }
  }, [myOrder])
      
  useEffect(()=>{

    setLoadingUser(true); 
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        setLoadingUser(true); 
        getUser(u.uid, async (user) =>  { 
          setUser(user);
        })
        getOrder(id,  o => {
            if (o  ){ 
                setMyOrder(o);
                setValue("deliveryNumber", o.deliveryNumber)
                setValue("orderstatus" ,o.orderstatus )
            }else{  
                // alert("Aucun produit au panier"); 
                toastr.error('Vous n\'avez pas accès a cette commande');
            }
            setLoadingUser(false);
        })
      } else {
        setLoadingUser(false);
        history.push("/orders");
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);

  }, [])
  function getstatus(o) {
    let v = {
      color: "black",
      name: "Chargement ..",
    };

    switch (o?.orderstatus){
      case "finished":
        v = {
          color: "green",
          name: "Terminé",
        };
        break;
      case "inprogress":
        v = {
          color: "orange",
          name: "En cours de traitement",
        };
        break;
        default:
          v = {
            color: "red",
            name: "Annulé",
          }
          break;
    }
    return v;
  }
  const  groupBy = (xs, key) => {
    return xs.reduce((rv, x)=> {
      // eslint-disable-next-line no-param-reassign
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    };
  const pPanier = (p) => { 
    const dss = Object.entries(groupBy(p, 'title')); 
    return dss;
  }

  const totalPrice = (data) => {
    let product = data[0].type;
    const qte = getQte(data);
    let e = product.price;
    product?.pricePer?.forEach((ml, i )=>{
      let max = product?.pricePer[i + 1] ? (product?.pricePer[i + 1]?.nbr - 1) : 99;
      // console.log(ml.nbr , qte, max, ml.price);
      // if (qte >= ml.nbr && qte <= max ){
        if (qte >= ml.nbr && qte <= max || (i + 1 === product?.pricePer?.length && max === 99 && qte >= ml.nbr && qte >= max )){
        // il y a autant de photo a imprimer que le maximum de cette unité donc le prix unitaire est celui ci.
        e = ml.price;
      }
    })
    // let price = qte * (product.price / 100).toFixed(2)
    let price = qte * (e / 100).toFixed(2)
    // console.log(price)
    return price.toFixed(2); 
  }

  const getQte = (data) => {
    let total = 0 
    data?.forEach(d => {
      total +=  Number(d.quantity);
    });
    return total;
  }

  const getPriceUnitaire = (p = null) => {
    let e =   p[1][0].type.price;
    let qte = getQte(  p[1]); 
    p[1][0].type?.pricePer?.forEach((ml, i )=>{
      let max = p[1][0].type?.pricePer[i + 1] ? (p[1][0].type?.pricePer[i + 1]?.nbr - 1) : 99;
      // console.log(ml.nbr , qte, max, ml.price);
      // if (qte >= ml.nbr && qte <= max ){
        if (qte >= ml.nbr && qte <= max || (i + 1 === p[1][0].type?.pricePer?.length && max === 99 && qte >= ml.nbr && qte >= max )){
        // il y a autant de photo a imprimer que le maximum de cette unité donc le prix unitaire est celui ci.
        e = ml.price;
      }
    })
    return e;
  }

  function subn() {


    let infopack = {};
    if (myOrder?.packs){
      let s = myOrder?.packs;
      infopack = s;
      // console.log("ici", s) 
    }else{
      infopack  = null;
    }
    

    // prix sous total:
    let sub = 0; 
    if (myOrder && myOrder?.products){
      pPanier(myOrder?.products).forEach((d, i) => { 
        let ds = 0;

        if (d[0] === infopack?.product?.title){
          let nb = 0 ;
          d[1].forEach(s => {
            let q = Number(s.quantity);
          nb += q
          });

          if (nb > infopack.pack.nbr){
            // plus que le nombre du pack actuel
            const price = (Number(getPriceUnitaire(d) )/ 100);
            let nb = 0 ;
            d[1].forEach(s => {
              let q = Number(s.quantity);
            nb += q
            });
            ds = price * nb;
            sub += ds;
          }else{
            // moins ou égal a la quantité max du pack
            sub += (Number(infopack.pack.price)/100) * infopack.pack.nbr;
          }


        }else{

          const price = (Number(getPriceUnitaire(d) )/ 100)  ;
          let nb = 0 ;
          d[1].forEach(s => {
            let q = Number(s.quantity);
          nb += q
          });
          ds = price * nb;
          sub += ds;
  
        }




      }); 
    } 
    return sub ;
  };


  function sub(f = false) {


    let infopack = {};
    if (myOrder?.packs){
      let s = myOrder?.packs;
      infopack = s;
      // console.log("ici", s) 
    }else{
      infopack  = null;
    }
    

    // prix sous total:
    let sub = 0; 
    if (myOrder && myOrder?.products){
      pPanier(myOrder?.products).forEach((d, i) => { 
        let ds = 0;

        if (d[0] === infopack?.product?.title){
          let nb = 0 ;
          d[1].forEach(s => {
            let q = Number(s.quantity);
          nb += q
          });

          if (nb > infopack.pack.nbr){
            // plus que le nombre du pack actuel
            const price = (Number(getPriceUnitaire(d) )/ 100);
            let nb = 0 ;
            d[1].forEach(s => {
              let q = Number(s.quantity);
            nb += q
            });
            ds = price * nb;
            sub += ds;
          }else{
            // moins ou égal a la quantité max du pack
            sub += (Number(infopack.pack.price)/100) * infopack.pack.nbr;
          }


        }else{

          const price = (Number(getPriceUnitaire(d) )/ 100)  ;
          let nb = 0 ;
          d[1].forEach(s => {
            let q = Number(s.quantity);
          nb += q
          });
          ds = price * nb;
          sub += ds;
  
        }




      }); 
    } 
    if (f && myOrder?.deliveryPrice){
      sub = sub - myOrder?.deliveryPrice
    } 
    return sub.toFixed(2);
  };

  const retry  = async (filepth,lpath, cb ) => {
    setTimeout(async () => {
      try {
        await fetch(`${API_URL}uploadDropbox`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ filepth: filepth, lpath: lpath}),
        })
        .then((res) => res.json())
        .then((data) => { 
          if (data.success){
            cb()
          }else{ 
            // retry 
            retry(filepth, lpath, cb)
            toastr.error("Error", "Renvoie automatique")
          }
        }).catch((err) => {
          console.log("error", err)
          retry(filepth, lpath, cb)
          toastr.error("Error", "Renvoie automatique")
        })
      } catch (errs) {
        retry(filepth, lpath, cb)
        console.log("error", errs)
        toastr.error("Error", "Renvoie automatique")
      }
    }, 100)
  }

  const sendFiles = () => {
    if (sendMode){
      return
    }
    // envoie des fichiers sur dropbox
    if(images.length > 0){
      if(confirm(`Il y a ${images.length} image(s) à envoyer sur dropbox, continuer?`)){
        setSendMode(true) 
        let total = 0;
        let sendts = 0
        setTot(total)
        setSendT(sendts)
        myOrder?.products?.forEach(async (p) => {
        if (p?.resultat){
          const link = p?.resultat;
          let pre  = link.split('?');
          let d = pre[0].split('/');
          let n = d[d.length - 1]; 
          // console.log("link", link)
          for (let pas = 0; pas < Number(p?.quantity); pas++) {
            const path = `${n.split(".")[0]}-${pas + 1}.${n.split(".")[1]}`;
            const filepth = p?.resultat;
            const lpath = `/${myOrder.orderNumber}/${p?.upName !== null && p?.upName !== undefined ? p?.upName + '-' : ''}${p?.title}${p?.option !== undefined && p?.option !== null ? '-' + p?.option : ''}/${path}`;
            total += 1;
            setTot(total)
            setTimeout(async () => {
              await fetch(`${API_URL}uploadDropbox`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ filepth: filepth, lpath: lpath}),
              })
              .then((res) => res.json())
              .then(async (data) => {
                console.log("data", data); 
                if (data.success){
                  sendts += 1;
                  setSendT(sendts)
                  if (sendts === total){
                    setSendMode(false)
                    toastr.success("Envoie terminé", `${sendts}/${total} photos envoyé`)
                  }
                }else{
                  toastr.error("Error", "Une erreur s'est produite lors de l'envoie d'une photos")
                  // retry 
                  retry(filepth, lpath, () => {
                    sendts += 1;
                    setSendT(sendts)
                    if (sendts === total){
                      setSendMode(false)
                      toastr.success("Envoie terminé", `${sendts}/${total} photos envoyé`)
                    }
                  })
                }
              }).catch(async (err) => {
                console.error("error", err)
                 // retry 
                 retry(filepth, lpath, () => {
                  sendts += 1;
                  setSendT(sendts)
                  if (sendts === total){
                    setSendMode(false)
                    toastr.success("Envoie terminé", `${sendts}/${total} photos envoyé`)
                  }
                })
                toastr.error("Error", "Une erreur s'est produite, renvoie automatique")
              })
            }, 100*total);
          }
        }
      })
      }
    }
  }

  const update = (value) => {
    // formulaire niveau1 vendeur
    if (loadingForm1) {
      return;
    }

    setLoadingF1(true);
    db.collection("orders").doc(myOrder.id).update({
      deliveryNumber: (myOrder.livraison ? value?.deliveryNumber?.trim() : null),
      orderstatus: value?.orderstatus,
    }) 
    .then(async () => {
      let ps = {...myOrder}
      ps.deliveryNumber = myOrder.livraison ? value?.deliveryNumber?.trim() : null;
      ps.orderstatus = value?.orderstatus;
      setMyOrder(ps)
      toastr.success("Données de commande mis à jour!");
      let ov = "En cours"
      if (value?.orderstatus){
        switch (value?.orderstatus) {
          case "inprogress":
            ov = "En cours"
            break;
          case "finished":
            ov = "Terminé"
            break;
          case "canceled":
            ov = "Annulé"
            break; 
          default:
            break;
        }
      }  

      fetch(`${API_URL}sendUpdateEmail`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email:myOrder?.user.email, link: window.location.href, status: ov, livraison: myOrder.livraison ? value?.deliveryNumber?.trim().length > 0 ? value?.deliveryNumber?.trim() : null : null }),
      }) 
      setLoadingF1(false);
    })
    .catch((err) => {
      setLoadingF1(false);
      toastr.error("Une erreur s'est produite!");
      console.log("updateVendorForm", err);
    });

  }

  const repondre = () => {
    let msg = prompt(`Veuillez entrer votre message:`);
    if (msg !== null) {
      // console.log('msg', msg)
      let aw = myOrder?.awser || []
      aw.push(msg);
      let ordersd = myOrder;
      ordersd.awser = aw
        fetch(API_URL + "noteemail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: myOrder?.user.email, info: {message: msg,orderNumber:myOrder.orderNumber   },id:myOrder.id}),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success){

              db.collection("orders").doc(myOrder.id).update({ 
                awser: aw,
              }) 
              .then(async () => {
                setMyOrder(ordersd);
              }).catch((err) => {
                toastr.error('Erreur', err)
              })
            }
          }).catch((err) => {
            toastr.error('Erreur', err)
          })

        

      
    }
  }

  function cfL(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

return ( 
    <>
    {myOrder && (
      <div class="container">
      <h1 class="mt-5" style={{textAlign:"left"}}>Commande #{myOrder.orderNumber}</h1> 
      <div class="row mb-5" style={{textAlign:"left"}}>
      {sendMode && (
        <>
        <p>Envoie des photos en cours ({sendt}/{tot})</p>
        <ProgressBar animated variant="success" now={(sendt/tot)*100} style={{marginBottom:30, padding:0}} />
        </>
      )}
      <p>Statut de la commande : <span class="badge rounded-pill" style={{ backgroundColor: getstatus(myOrder)?.color || "black" }}>{getstatus(myOrder)?.name}</span></p>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-8">
                                <div class="card mb-4">
                                  <div class="card-body">
                                    <div class="mb-3 d-flex justify-content-between">
                                      <div>
                                        <span class="me-3">{moment(myOrder?.order_date?.toDate()).format("DD/MM/YYYY")}</span> 
                                        
                                        {myOrder?.livraison ? (<span class="badge rounded-pill bg-info">
                                          LIVRAISON
                                        </span>) : ( <span class="badge rounded-pill bg-warning">
                                          EN MAIN PROPRE
                                        </span>)}
                                       
                                      </div>
                                      <div class="d-flex">
                                        {myOrder?.invoice_url && (
                                          <a href={myOrder?.invoice_url} target="_blank" class="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text" >
                                          <i class="bi bi-download"></i>{" "}
                                          <span class="text">Facture</span>
                                        </a>
                                        )}
                                        
                                        <div class="dropdown">
                                          <button
                                            class="btn btn-link p-0 text-muted"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                          >
                                            <i class="bi bi-three-dots-vertical"></i>
                                          </button>
                                          <ul class="dropdown-menu dropdown-menu-end">
                                            <li>
                                              <a class="dropdown-item" href="#">
                                                <i class="bi bi-pencil"></i> Edit
                                              </a>
                                            </li>
                                            <li>
                                              <a class="dropdown-item" href="#">
                                                <i class="bi bi-printer"></i>{" "}
                                                Print
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <table class="table table-borderless">
                                      <tbody>
                                      {myOrder && myOrder?.products?.length > 0 && pPanier(myOrder?.products).map((p, i)=> {
                                          return  (
                                            <tr>
                                            <td>
                                              <div class="d-flex mb-2"> 
                                              <div class="flex-shrink-0">
                                              <img
                                                src={p[1][0].type.image}
                                                alt=""
                                                width="35"
                                                class="img-fluid"
                                              />
                                            </div>
                                                <div class="flex-lg-grow-1 ms-3">
                                                  <h6 class="small mb-0">
                                                    <a href="#" class="text-reset">
                                                     {p[0]}
                                                    </a>
                                                  </h6>
                                                  <span class="small">
                                                    {myOrder?.packs && p[1][0].type.title === myOrder?.packs.product.title ? (
                                                      <>Packs {myOrder?.packs?.pack?.nbr} photos</>
                                                    ) : <>unitaire: {(getPriceUnitaire(p)/ 100).toFixed(2)} €</>}
                                                    
                                                    
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                            {myOrder?.packs && p[1][0].type.title === myOrder?.packs.product.title ? (<td>{getQte(p[1])}/{myOrder?.packs?.pack?.nbr}</td>) : ( <td>x{getQte(p[1])}</td>)}
                                            {myOrder?.packs && p[1][0].type.title === myOrder?.packs.product.title ? (<td class="text-end">{((myOrder?.packs?.pack?.price / 100)*myOrder?.packs?.pack?.nbr) .toFixed(2)} €</td>) : (<td class="text-end"> {totalPrice(p[1])} €</td>)}
                                            
                                          </tr>)
                                      })}

                                        

 
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="2">Sous-total</td>
                                          <td class="text-end">{sub()} €</td>
                                        </tr>
                                        {myOrder?.livraison ?
                                        (
                                          <tr>
                                          <td colspan="2">LIVRAISON</td>
                                          <td class="text-end">{
                                            myOrder?.reduction && myOrder?.deliveryPrice ? myOrder?.deliveryPrice.toFixed(2) :
                                          Number((myOrder.amount-(subn()*100))/100).toFixed(2) 
                                          
                                          } €</td>
                                        </tr>
                                        ) : (
                                          <tr>
                                            {myOrder?.tax && (
                                            <>
                                            <td colspan="2">FRAIS DE SERVICE</td>
                                            <td class="text-end">{Number(myOrder.tax/100).toFixed(2) } €</td></>
                                            )}
                                          
                                        </tr>
                                        )}
                                        {myOrder?.reduction && myOrder?.codepromo && (
                                          <tr>
                                          <td  style={{color:'orange'}} colspan="2"><b>CODE PROMO</b></td>
                                          <td style={{color:'orange'}} class="text-end">
                                          <b>-{myOrder?.reduction?.toFixed(2)} €</b>
                                          </td>
                                          </tr>
                                        )}
                                        
                                        {/* <tr>
                                          <td colspan="2">
                                            Discount (Code: NEWYEAR)
                                          </td>
                                          <td class="text-danger text-end">
                                            -$10.00
                                          </td>
                                        </tr> */}
                                        <tr class="fw-bold">
                                          <td colspan="2">TOTAL</td>
                                          <td class="text-end">{Number((myOrder.amount / 100)).toFixed(2)}</td>
                                          {/* <td class="text-end">{myOrder.livraison ?  Number((myOrder.amount / 100) - (myOrder?.reduction ? myOrder?.reduction : 0) ).toFixed(2)  : myOrder?.tax ? Number(subn() + myOrder.tax/100 - (myOrder?.reduction ? myOrder?.reduction : 0)).toFixed(2) : sub(true)} €</td> */}
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                                <div class="card mb-4">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-lg-6">
                                        <h3 class="h6">Methode de paiement</h3>
                                        <p>
                                          {myOrder.last4} <br />
                                          Total: {Number((myOrder.amount / 100)).toFixed(2)}€{" "}
                                          {myOrder?.pay_status === "paid" ? (<span class="badge bg-success rounded-pill">
                                            PAYER
                                          </span>
                                          ) : (
                                            <span class="badge bg-warning rounded-pill">
                                            {myOrder?.pay_status}
                                          </span>
                                          )}
                                        </p>
                                      </div>
                                      <div class="col-lg-6">
                                        <h3 class="h6">Adresse de facturation</h3>
                                        <strong>{`${cfL(myOrder.client?.lastName)} ${cfL(myOrder.client?.firstName)}`}</strong>
                                        <address>
                                      <strong>{myOrder.client?.addresspersolocation}</strong>
                                      {myOrder.client?.complementpersolocation && (
                                        <><br />
                                        {myOrder.client?.complementpersolocation}
                                        </>
                                      )}
                                      <br />
                                      {myOrder?.client?.citypersolocation} {myOrder?.client?.zipcodepersolocation}
                                      <br />
                                      {myOrder?.client?.phoneperso}
                                    </address>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div class="card mb-4">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <h3 class="h6">Vos photos</h3> 

                                        {!imagesL && images.length > 0 ? (
                                         <Container>
                                          {rows(images).map((row, index) => (
                                            <Row key={index}>
                                              {row.map((item, index) => (
                                                <Col key={index} sm={1} style={{position:"relative", maxWidth:80}}>
                                                   
                                                  <p style={{position: "absolute", backgroundColor: "pink", color: "black", width: "25px", height: "25px", borderRadius: "12.5px", textAlign: "center", left: "-4px", fontSize:10, paddingTop:4}}>x{item.nb}</p>
                                                      <img
                                                        style={{borderWidth: 1, borderColor: "black", borderStyle: "solid"}}
                                                        src={item.url}
                                                        alt=""
                                                        width="70" 
                                                        class="img-fluid"
                                                    /> 
                                                </Col>
                                              ))} 
                                            </Row>
                                          ))}
                                        </Container>)
                                         : <p>Chargement ...</p>}
                                        
                                        {/* <div class="container">
                                          <div class="row">

                                          {!imagesL && images.length > 0 ? images.map((p)=> {
                                          return <div class="col-sm" style={{position:"relative", maxWidth:80}}>
                                            <p style={{position: "absolute", backgroundColor: "pink", color: "black", width: "25px", height: "25px", borderRadius: "12.5px", textAlign: "center", left: "-4px", fontSize:10, paddingTop:4}}>x{p.nb}</p>
                                                <img
                                                  style={{borderWidth: 1, borderColor: "black", borderStyle: "solid"}}
                                                  src={p.url}
                                                  alt=""
                                                  width="70" 
                                                  class="img-fluid"
                                              />
                                            </div>
                                         }) : <p>Chargement ...</p>}
 
                                          </div>
                                        </div>  */}
                                        {
                                          !imagesL && imagess.length > 0 && (<>
                                             <br/>
                                            <hr/>
                                            <h5>Mode Gallerie</h5> 
                                            <ImageGallery items={imagess} showThumbnails={false} showPlayButton={false}/>
                                     

                                          </>)
                                        }
                                        </div>
                                       
                                    </div>
                                  </div>
                                </div>


                              </div>
                              <div class="col-lg-4">
                                <div class="card mb-4">
                                  <div class="card-body">
                                    <h3 class="h6">Note du client</h3>
                                    <p>
                                      {myOrder?.client?.note || 'Aucune note'}
                                    </p>
                                    {myOrder?.awser?.length > 0 && (
                                      <>
                                      <p><b>Vos réponses:</b><br/>
                                      {myOrder?.awser.map((r) => {
                                        return <span>{r}<br/></span>
                                      })}
                                      </p>
                                      </>
                                    )}
                                        {myOrder?.client?.note && (
                                          <p>
                                            <a href="#" onClick={repondre}>Répondre au client</a>
                                          </p>
                                        )}
                                  </div>
                                </div>
                                {myOrder.livraison && (
                                  <div class="card mb-4">
                                  <div class="card-body">
                                    <h3 class="h6">Information de livraison</h3>
                                    {myOrder?.deliveryNumber && <strong>Numéro de livraison : <a 
                                        class="text-decoration-underline" target="_blank" href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${myOrder?.deliveryNumber}`}>{myOrder?.deliveryNumber}</a></strong>}
                                    <hr />
                                    <h3 class="h6">Adresse</h3>
                                    <address>
                                      <strong>{myOrder.client?.addresspersolocation}</strong>
                                      
                                      {myOrder.client?.complementpersolocation && (
                                        <><br />
                                        {myOrder.client?.complementpersolocation}
                                        </>
                                      )}
                                      <br />
                                      {myOrder?.client?.citypersolocation} {myOrder?.client?.zipcodepersolocation}
                                      <br />
                                      {myOrder?.client?.phoneperso}
                                    </address>
                                    <span style={{ fontSize: 10 }}>Les estimations de livraison tiennent compte des jours non ouvrables, tels que les week-ends et les jours fériés.</span> 
                          
                                  </div>
                                </div>
                                )}
                                {user.isAdmin && (
                                  <div class="card mb-4">
                                  <div class="card-body">
                                    <h3 class="h6">Espace administration</h3>
                                    <p>Email du client : <a href={`mailto:${myOrder?.user.email}`}>{myOrder?.user.email}</a><br/>
                                   Téléphone du client : <a href={`tel:${myOrder?.user.email}`}>{myOrder?.client?.phoneperso}</a></p>
                                   {!imagesL && images.length > 0 && (<p>Renvoyer les fichiers sur dropbox: <a href="#" onClick={sendFiles}>{sendMode ? "Chargement ...": "Envoyer"}</a></p>)}
                                    <form
                                      class="needs-validation"
                                      novalidate 
                                      onSubmit={handleSubmit(update, (err) => {
                                        console.log(err);
                                      })}
                                    >

                                      <div class="row g-3">
                                      
                                    {myOrder.livraison && 
                                    
                                    <div class="col-sm-6">
                                          <label for="deliveryNumber" class="form-label">
                                            Numéro de livraison
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            id="deliveryNumber"
                                            placeholder="Numéro de livraison" 
                                            onChange={(e) =>
                                              setValue("deliveryNumber", e.target.value)
                                            }
                                            {...register("deliveryNumber")}
                                          />
                                          <div class="invalid-feedback">
                                            Prénom est requis
                                          </div>
                                        </div>

                                      }
                                    <hr /> 
                                    <div class="col-md-6">
                                        <label
                                          for="countrylocation"
                                          class="form-label"
                                        >
                                          Statut de la commande
                                        </label>
                                        <select
                                          class="form-select"
                                          id="orderstatus"
                                          required
                                          onChange={(e) =>
                                            setValue(
                                              "orderstatus",
                                              e.target.value
                                            )
                                          }
                                          {...register("orderstatus")}
                                        > 
                                        <option value="inprogress">En cours</option>
                                        <option value="finished">Terminé</option>
                                        <option value="canceled">Annulé</option>
                    
                                        </select> 
                                      </div>
                                     </div>
                                     <hr class="my-4" />

                                        <button
                                          class="w-100 btn btn-dark btn-lg"
                                          type="submit"
                                          disabled={loadingForm1}
                                        >
                                          {loadingForm1
                                            ? "Chargement ...."
                                            : "Enregistrer et mettre à jour"}
                                        </button>
                                      </form>

                                  </div>
                                </div>

                                )}
                              </div>
                            </div>
                          </div> 
  
      </div>
    </div>
    </div>

    )}
    
  </>
  )};
  export default Order;
  