function Footer() {
    return (
        <div class="container">
          
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <p className="mb-0 text-muted" >&copy; 2024 ZePixBox</p>

    <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
      <svg class="bi me-2" width="40" height="32"></svg>
    </a>

    <ul class="nav col-md-5 justify-content-end"> 
      <li class="nav-item"><a href="/about" class="nav-link px-2 text-muted">A propos</a></li>
      <li class="nav-item"><a href="/mentionslegales" class="nav-link px-2 text-muted">Mentions Légales</a></li>
      
      <li class="nav-item"><a href="/cgv" class="nav-link px-2 text-muted">CGV</a></li>
      <li class="nav-item"><a href="/contact" class="nav-link px-2 text-muted">Contact</a></li>
      <li class="nav-item"><ul class="list-unstyled d-flex">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/paiement.png"}
              alt=""
              style={{ height: 50, position: "relative", top: -10 }}
            /> 
          </ul> </li>
    </ul>
    
    <p class=" mb-0 text-muted">Ce site a été financé par l'Union Européenne dans le cadre du programme FEDER-FSE+ Réunion dont l'Autorité de gestion est la Région Réunion. L'Europe s'engage à La Réunion avec le fonds FEDER.</p>
    <p style={{margin:"auto"}}>
            <a href="www.regionreunion.com" target="_blank">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/logo-region-reunion.jpg"}
              alt=""
              style={{ height: 100, padding:10 }}
            />
            </a> </p>
     </footer>
</div>
    );
};

export default Footer;