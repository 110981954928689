import React, { useEffect } from "react";
import "../App.css";
import { useForm } from "react-hook-form";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";

const ForgetPass = () => {
  const history = useHistory();
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const redirection = (user) => {
    switch (user?.role) {
      case "vendor":
        history.push("/tableau-de-bord#dashboard");
        break;
      case "pendingvendor":
        history.push("/tableau-de-bord#becomevendor");
        break;

      default:
        // redirection à l'accueil
        history.push("/");
        break;
    }
  };

  useEffect(() => {
    // check if user is login
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          redirection(user);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);
  }, []);

  const forgetPass = (value) => {
    if (loading) {
      return;
    }
    setLoading(true);
    firebase.auth()
      .sendPasswordResetEmail(value.mail)
      .then(function () {
        // Password reset email sent.
        history.push({
          pathname: "/connexion",
          state: {
            forgetpass: true,
          },
        });
      })
      .catch(function (error) {
        setLoading(false);
        // Error occurred. Inspect error.code.
        console.log("forgetpass", error);
        if (error.code === "auth/user-not-found") {
          alert("L'email ne correspond à aucun compte.");
        } else {
          alert("Une erreur s'est produite, réessayer plus tard.");
        }
      });
  };

  return (
    <div class="container  p-4 d-flex justify-content-center">
      <div
        class="tab-content"
        style={{ paddingTop: "5%", paddingBottom: "5%" }}
      >
        {loadingUser ? (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            <p style={{ textAlign: "center" }}>Chargement ... </p>
          </div>
        ) : (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            <form
              onSubmit={handleSubmit(forgetPass, (err) => {
                console.log(err);
              })}
            >
              <h2 class="text-center p-4">Mot de passe oublié</h2>

              <div class="form-outline mb-4">
                <label class="form-label" for="mail">
                  Email
                </label>
                <input
                  type="email"
                  id="mail"
                  name="mail"
                  class="form-control"
                  required
                  onChange={(e) => setValue("mail", e.target.value)}
                  ref={register("mail")}
                />
              </div>

              <button
                type="submit"
                class="btn btn-dark btn-block mb-4"
                disabled={loading}
              >
                {loading ? "Chargement ... " : "Envoyer"}
              </button>
            </form>

            <div class="text-center">
              <p>
                <a href="/connexion">Se connecter</a>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ForgetPass;
