import Carousel from 'react-bootstrap/Carousel';

function Header() {
   return ( 
    <div >
      <Carousel className='d-none d-lg-block'>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={process.env.PUBLIC_URL + "/assets/images/1.jpg"}
              alt="First slide"
            />

            <Carousel.Caption>
              <div class="container">
                <div class="carousel-caption text-start">
                  <h1>Tirage photo</h1>
    
                    <>
                      <p>
                        Carré, panoramique, normal, grand format et bien plus ...
                      </p>
                      <p>
                        <a class="btn btn-lg btn-primary" style={{background: "rgb(255, 0, 173)", borderColor: "black"}} href="/c/all">
                          J'imprime
                        </a>
                      </p>
                    </> 
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item> 

          <Carousel.Item>
            <img
              className="d-block w-100"
              src={process.env.PUBLIC_URL + "/assets/images/2.jpg"}
              alt="First slide"
            />

            <Carousel.Caption>
              <div class="container">
                <div class="carousel-caption text-start">
                  <h1>10x15</h1>
    
                    <>
                      <p>
                        Format 10x15, à l'unité ou en pack
                      </p>
                      <p>
                        <a class="btn btn-lg btn-primary" style={{background: "rgb(255, 0, 173)", borderColor: "black"}} href="/p/mgrH9rAgvgkGi1WERCxV/1">
                          J'imprime
                        </a>
                      </p>
                    </> 
                </div>
              </div>
            </Carousel.Caption>

            </Carousel.Item> 
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={process.env.PUBLIC_URL + "/assets/images/3.jpg"}
              alt="First slide"
            />

            <Carousel.Caption>
              <div class="container">
                <div class="carousel-caption text-start">
                  <h1>Poster</h1>
    
                    <>
                      <p>
                        Parce qu'il y a des moments inoubliables.
                      </p>
                      <p>
                        <a class="btn btn-lg btn-primary" style={{background: "rgb(255, 0, 173)", borderColor: "black"}} href="/c/poster">
                          J'imprime mon poster
                        </a>
                      </p>
                    </> 
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item> 


        </Carousel>
      
    </div> 
  
   );

}

export default Header;