// import firebase from 'firebase/app';
import firebase from "firebase/compat/app"; 
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions'; 
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
// import { getStorage } from "firebase/storage"; 

const config = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
};
firebase.initializeApp(config);
// const auth = firebase.auth();
firebase.firestore();  
firebase.storage();
firebase.auth() 
// firebase.initializeServerApp(config)
// // initializeApp();

// export default firebase;
 

// Initialize Firebase
// const app = initializeApp(config);
// const firebase = getFirestore(app); 
// const storage = getStorage(app); 
// const auth = getAuth(app);
// export {
//   storage,
//   auth
// }
// const storage = getStorage(firebase); 
// firebase.firestore();  
// firebase.storage();
// firebase.auth()
// firebase.function()

// Initialize Firestore
export default firebase;