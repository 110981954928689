
const About = () => (
  <>
  <div class="container">
  <h1 class="mt-5">A propos</h1>
  <span class="fs-4">Personaprint</span>
  <p class="lead">
    ZePixBox - est représentée par Personnaliz.re.
  </p>
  <div class="row mb-5">
    <div class="col-12 col-md-4 color-primary">Adresse</div>
    <div class="col-12 col-md-8">
    ZePixBox 
      <br />
      {/* Apt 42
      <br /> */}
      Réunion, Île de la
    </div>
    {/* <div class="col-12 col-md-4 color-primary">Téléphone</div> */}
    {/* <div class="col-12 col-md-8">
      +262 693 32 25 25{" "}
      <small class="font-italic">
        {" "}
        (Ne pas utiliser pour contacter le Service Client)
      </small>
    </div> */}
    <div class="col-12 col-md-4 color-primary">Téléphone</div>
    <div class="col-12 col-md-8">
      <a href="tel:0692674905" class="unselectable">
      0692674905
      </a>
    </div>
    <div class="col-12 col-md-4 color-primary">E-Mail</div>
    <div class="col-12 col-md-8">
      <a href="mailto:contact@personnaliz.re" class="unselectable">
        contact@personnaliz.re
      </a>
    </div>

    <div class="col-12 col-md-4 color-primary">
      No. d'identification fiscale
    </div>
    <div class="col-12 col-md-8">83216400800027</div>
  </div>
  <p>
    Information concernant la résolution de litiges en ligne: La Commission
    Européenne a créé un site web afin de traiter les litiges en ligne (ODR
    en français). Le site de la ODR est un lieu qui assiste à l'accord
    extrajudiciaire de litiges provenant des obligations contractuelles
    issues de contrats d'achat et vente en ligne. Le client peut accéder au
    site de la ODR en cliquant sur le lien suivant:{" "}
    <a
      href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR"
      target={"_blank"}
    >
      http://ec.europa.eu/consumers/odr/
    </a>
  </p>
</div>
</>
  );
  export default About;
  