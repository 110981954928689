/* eslint-disable no-restricted-globals */
import React , { useEffect, useState } from "react"; 
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const CodePromoAdmin = () => { 

    const db = firebase.firestore();
    const history = useHistory();

    const [myOrders, setMyOrders] = React.useState([]);
    const [loadingUser, setLoadingUser] = React.useState(false);
    
  const [l, setL] = useState(false)
 
    const [user, setUser] = React.useState(null); 
    const [myCodes, setMyCodes] = useState([]);

    const getUser = async (uid, cb) => {
        await db
          .collection("users")
          .doc(uid)
          .get()
          .then(async (docd) => {
            var user = docd.data();
            user.id = docd.id;
            cb(user);
          })
          .catch((error) => {
            cb(null);
            console.error(error)
          });
          
      };

    const getUsers = async () => {  
        let orders =[]
        await db
              .collection("codes")   
              .where('delete', '==', false)
              .get()
              .then(async (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  var order = doc.data();
                  order.id = doc.id;
                  orders.push(order)
                })
                // console.log('codes', orders.userId)
                setMyCodes(orders);
                setL(false)
              })
              .catch((error) => { 
                setL(false)
                console.error(error);
              });
    }
 
     

  useEffect(()=>{
    setL(true)
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        setLoadingUser(true);   
          getUser(u.uid, async (user) =>  { 
            setUser(user);
            firebase.auth().languageCode = "fr";  
            if (user.isAdmin === true){ 
                getOrders()
                getUsers()
               setLoadingUser(false);
            }else{
              history.push("/");
              toastr.error("Vous n'avez pas le droit d'accès a cette page.")
            }
          });
        
        
      } else {
        setLoadingUser(false);
        history.push("/");
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);

  }, [])

  function isActif(dateFournie)  {
    // const dateFournie = new Date('2023-04-26'); // Date fournie à vérifier
// console.log('isActif', dateFournie)
const dateActuelle = new Date(); // Date actuelle

if (dateFournie < dateActuelle) {
//   console.log("La date fournie est passée.");
  return false;
} else if (dateFournie > dateActuelle) {
//   console.log("La date fournie est dans le futur.");
  return true;
} else {
//   console.log("La date fournie est la même que la date actuelle.");

  return true;
}
  }

  const delIt = async (id) => {
    setL(true);
    try {
      firebase.firestore().collection('codes').doc(id).update({delete:true}).then((snap)=> {
        toastr.success("Le code à été supprimé");
        getUsers()
      })
    } catch (err) {
      setL(false);
      // eslint-disable-next-line no-console
      console.log(err);
      toastr.error("Une erreur s'est produite");
    } 
  };

  const del = (id) => {
    if(confirm(`Êtes vous certain de vouloir supprimer le contrat ?`)){
      delIt(id);
    }
  }
  function getNb(id)  {
    let ors = [];
    myOrders?.forEach((o) => {
        if (o?.codepromo?.id === id){
            ors.push(o)
        }
    })
    return ors.length;
  }

  const getOrders = async (uid, cb) => {
    let ord  =[]
    await db
      .collection("orders")  
      .get()
      .then(async (querySnapshot) => { 
        querySnapshot.forEach((doc) => {
          var order = doc.data();
          order.id = doc.id;
          ord.push(order)
        })
        setMyOrders(ord);
      })
      .catch((error) => {
        console.log("getOrders error", error);
         
      });

  }
 

return ( 
    <>
    <div class="container">
    <h1 class="mt-5">Code promotionnelles</h1> 
    <div class="row mb-5" style={{textAlign:"left"}}>
    {l ? (
        <p>Chargement ...</p>
    ): (
<div class="table-responsive">
    {myCodes.length === 0 && <p>Aucun code</p>}
    <a class="mt-3 btn btn-primary btn-sl" href="/admin/codepromo/new" > 
     Nouveau code
     </a>
        <table class="table table-striped table-sm">
                        {myCodes?.length > 0 && (
                          <thead>
                            <tr>
                              <th scope="col">#</th> 
                              <th scope="col">Nom du code</th>
                              <th scope="col">CODE</th> 
                              <th scope="col">Date de départ</th>
                              <th scope="col">Date d'expiration</th> 
                              <th scope="col">Réduction</th>
                              <th scope="col">Nbr d'utilisation</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                        )} 
                        <tbody> 
                        {
                            myCodes?.map((p) => {
                                return (
                                    <> 
                                         <tr>
                                            <td>#{p.id} </td>
                                            <td>{p.namecode}</td>
                                            <td>{p.code}</td>
                                            <td>{p.datestart?.toDate().toLocaleDateString('fr-FR', {year: 'numeric',
  month: '2-digit',
  day: '2-digit'
})}</td>
                                            <td>{p.dateend.toDate().toLocaleDateString('fr-FR', {year: 'numeric',
  month: '2-digit',
  day: '2-digit'
})}</td>
                                            <td><Badge bg={p?.percent ? 'success': 'warning'} >{p.reduc} {p?.percent ? '%': '€'}</Badge></td>
                                            <td>{getNb(p?.id) || 0}</td>
                                            <td><Badge bg={isActif(p.dateend.toDate())? 'success' : 'danger'}>{isActif(p.dateend.toDate()) ? 'Actif' : 'Passé'}</Badge> {isActif(p.dateend.toDate()) && (
                                                // afficher les bouton 
                                                <>
                                                <a class=" btn btn-primary btn-sm" href={`/admin/codepromo/edit/${p.id}`} > 
                                                    Edit
                                                    </a>
                                                    <a style={{marginLeft:4}} class=" btn btn-warning btn-sm" href={`#`} onClick={() => del(p.id)} > 
                                                    Delete
                                                    </a>
                                                </>
                                            )}</td>
                                        </tr> 
                                    </>
                                )
                            })
                        }
                           
                        </tbody>
                      </table>
                    </div>
    )} 
       </div>
  </div>
  </>
  )};
  export default CodePromoAdmin;
  