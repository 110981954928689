import React , { useEffect, useState } from "react"; 
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const CodePromoAdminNew = () => { 

    const db = firebase.firestore();
    const history = useHistory(); 
    const [user, setUser] = React.useState(null); 
    const [loadingUser, setLoadingUser] = React.useState(false);
    const [l, setL] = useState(false)
    const [loadingForm1, setLoadingF1] = useState(false)
 
    const [formData, setFormData] = useState({});
    const mp =new Date()
    const year = mp.getFullYear(); 
    
    const month = String(mp.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = String(mp.getDate()).padStart(2, '0'); 
    const [dateend, setDateEnd] = React.useState(`${year}-${month}-${day}`);
    const [datearrive, setDateArrive] = React.useState(`${year}-${month}-${day}`);
    const { register, handleSubmit, setValue } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
      });

      const getUser = async (uid, cb) => {
        await db
          .collection("users")
          .doc(uid)
          .get()
          .then(async (docd) => {
            var user = docd.data();
            user.id = docd.id;
            cb(user);
          })
          .catch((error) => {
            cb(null);
          });
          
      };
      const updateUserForm = (value) => {
        value.preventDefault()
        // formulaire niveau1 vendeur
        if (loadingForm1) {
          return;
        }
        console.log("namecode", value?.target?.namecode?.value.trim())
        const namecode = value?.target?.namecode?.value.trim();
        console.log("code", value?.target?.code?.value.trim())
        const code = value?.target?.code?.value.trim();
        console.log("datestart", value?.target?.datestart?.value.trim())
        const datestart = value?.target?.datestart?.value.trim();
        console.log("dateend", value?.target?.dateend?.value.trim())
        const dateend = value?.target?.dateend?.value.trim()
        console.log("reduction", value?.target?.reduction?.value.trim())
        const reduc = Number(value?.target?.reduction?.value.trim());
        console.log("percent", value?.target?.percent?.checked )
        const percent = value?.target?.percent?.checked;


        if (namecode?.length === 0){
            toastr.error("Le champs Nom (titre) est requis")
            return;
        }

        if (code?.length === 0){
            toastr.error("Le champscode est requis et nécessite 5 caractères minimum")
            return;
        }

        if (datestart?.length === 0){
            toastr.error("Le champs date de départ est requis ")
            return;
        }
        if (dateend?.length === 0){
            toastr.error("Le champs date de cloture est requis ")
            return;
        }
        if (reduc === 0){
            toastr.error("Le champs réduction est requis")
            return;
        }
        setL(true)

        db.collection("codes") 
        .add({
            uid: user.uid,
            namecode: namecode,
            code: code,
            datestart: new Date(datestart),
            dateend: new Date(dateend),
            reduc: reduc,
            percent: percent,
            use: 0,
            delete:false,
            _create_At: new Date(),
            _update_At: new Date(),
        })
        .then(async () => {
            toastr.success('Code enregistré')
            setL(false)
            history.push("/admin/codepromo");
        }).catch(err => {
            toastr.error('Erreur', err)
            setL(false)
        })

    }
      
      useEffect(()=>{
        setL(true)
        setLoadingUser(true);
        firebase.auth().onAuthStateChanged((u) => {
          if (u) { 
            setLoadingUser(true);   
              getUser(u.uid, async (user) =>  { 
                setUser(user);
                firebase.auth().languageCode = "fr";  
                if (user.isAdmin === true){  
                   setLoadingUser(false);
                   setL(false)
                }else{
                  history.push("/");
                  toastr.error("Vous n'avez pas le droit d'accès a cette page.")
                }
              });
            
            
          } else {
            setLoadingUser(false);
            history.push("/");
          }
        });
        setTimeout(() => {
          setLoadingUser(false);
        }, 5000);
    
      }, [])

    return ( 
        <>
        <div class="container">
        <h1 class="mt-5">Ajout de code promotionnelles</h1> 
        {loadingUser ? <p>Chargement ...</p> : (
            <div class="row mb-5" style={{textAlign:"left"}}> 
             
         <Form class="needs-validation"
        novalidate 
        onSubmit={updateUserForm}>
            <Form.Group className="mb-3" controlId="namecode">
            <Form.Label htmlFor="namecode">Nom du code</Form.Label>
                        <Form.Control
                            type="text"
                            id="namecode" 
                            name="namecode"
                            required
                        />
            </Form.Group>
            <Form.Group className="mb-3" controlId="code">
            <Form.Label htmlFor="code">CODE</Form.Label>
                        <Form.Control
                            type="text"
                            id="code" 
                            name="code"
                            required
                        />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="datestart">
                <Form.Label htmlFor="datestart">Date de départ</Form.Label>
                <Form.Control value={datearrive}  
                            required name="datestart" id="datestart" type="date" onChange={e => {
                            // 
                            // console.log("date", e?.target?.valueAsDate) 
                           let date = e?.target?.valueAsDate
                            const year = date.getFullYear();
                            const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
                            const day = String(date.getDate()).padStart(2, '0');

                        const formattedDate = `${year}-${month}-${day}`;
                        setDateArrive(formattedDate);
                        // console.log('date', formattedDate)
                            setFormData({ ...formData, start: formattedDate });
                        }}   />
            </Form.Group>


            <Form.Group className="mb-3" controlId="dateend">
                <Form.Label htmlFor="dateend">Date de cloture</Form.Label>
                <Form.Control value={dateend} 
                            required name="dateend" min={datearrive} id="dateend" type="date" onChange={e => {

                        let date = e?.target?.valueAsDate
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
                        const day = String(date.getDate()).padStart(2, '0');

                        const formattedDate = `${year}-${month}-${day}`;
                            setDateEnd(formattedDate);
                            setFormData({ ...formData, end: formattedDate });
                        }}   />
            </Form.Group>
            <Form.Group className="mb-3" controlId="reduction">
            <Form.Label htmlFor="reduction">Réduction (€ | %)</Form.Label>
                        <Form.Control
                            type="number"
                            id="reduction" 
                            name="reduction"
                            required
                        />
                        <Form.Check type="checkbox" 
                            id="percent" 
                            name="percent" label="En pourcentage" />

            
            </Form.Group> 

            <Form.Group className="mb-3" controlId="reduction">
                        <Button variant="primary" type="submit" disabled={l}>
                {l ? 'Chargement' : 'Enregistrer'}
            </Button>
                            </Form.Group>
        </Form>  
 
        </div> 
        )}
        
    </div>
    </>
    )};
  export default CodePromoAdminNew;
  