import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { useHistory } from "react-router-dom";
import ReactN from "reactn";

const MyAccount = () => {
  const [myOrders, setMyOrders] = useState([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const db = firebase.firestore();
  const history = useHistory();
  const [loadingForm1, setLoadingF1] = useState(false)

  const [user, setUser] = ReactN.useGlobal("user");
  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });



  const getUser = async (uid, cb) => {
    await db
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch(() => {
        cb(null);
      });
  };

      
  useEffect(()=>{

    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        getUser(firebase.auth().currentUser.uid, (u) => {
          setLoadingUser(false);
          setUser(u); 
        });
      } else {
        setLoadingUser(false);
        history.push("/");
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);

  }, [])


  const updateUserForm = (value) => {
    // formulaire niveau1 vendeur
    if (loadingForm1) {
      return;
    }

    setLoadingF1(true);


    db.collection("users")
      .doc(user.uid)
      .update({
        phoneperso: value?.phoneperso?.trim(),
        nom: value?.lastName?.trim(),
        prenom: value?.firstName?.trim(),
        displayName: `${value?.username?.trim()}`,
        addresspersolocation: value?.addresspersolocation?.trim() || null, 
        complementpersolocation: value?.complementpersolocation?.trim() || null, 
        countrypersolocation: value?.countrypersolocation?.trim() || null, 
        statepersolocation: value?.statepersolocation?.trim() || null, 
        zipcodepersolocation: value?.zipcodepersolocation?.trim() || null,  
        citypersolocation: value?.citypersolocation?.trim() || null,  
        _update_At: new Date(),
      })
      .then(async () => {
        toastr.success("Données utilisateur mis à jour!");
        setLoadingF1(false);
      })
      .catch((err) => {
        setLoadingF1(false);
        toastr.error("Une erreur s'est produite!");
        console.log("updateVendorForm", err);
      });
  }


  useEffect(() => {
    if (user) { 
      // votre compte utilisateur
      setValue("phoneperso", user.phoneperso || "");
      setValue("lastName", user.nom || "");
      setValue("firstName", user.prenom || "");
      setValue("username", user?.displayName || "");
      setValue("email", user?.email || ""); 
      setValue("citypersolocation", user?.citypersolocation || "");
      setValue("addresspersolocation", user?.addresspersolocation || ""); 
      setValue("complementpersolocation", user?.complementpersolocation || ""); 
      setValue("countrypersolocation", user?.countrypersolocation || ""); 
      setValue("statepersolocation", user?.statepersolocation || ""); 
      setValue("zipcodepersolocation", user?.zipcodepersolocation || "");  
    }
  }, [user]);


return ( 
    <>
    <div class="container">
    <h1 class="mt-5" style={{textAlign:"left"}}>Mon compte</h1> 
    <div class="row mb-5" >
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom" style={{textAlign:"left"}}>
                    
                    
    <div class="col-md-7 col-lg-8">
                        {user && (
                          <form
                            class="needs-validation"
                            novalidate
                            enctype="multipart/form-data"
                            onSubmit={handleSubmit(updateUserForm, (err) => {
                              console.log(err);
                            })}
                          >
                            <div class="row g-3">
                              <div class="col-sm-6">
                                <label for="firstName" class="form-label">
                                  Prénom
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="firstName"
                                  placeholder=""
                                  required
                                  onChange={(e) =>
                                    setValue("firstName", e.target.value)
                                  }
                                  {...register("firstName")}
                                />
                                <div class="invalid-feedback">
                                  Prénom est requis
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <label for="lastName" class="form-label">
                                  Nom
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="lastName"
                                  placeholder=""
                                  name="lastName"
                                  required
                                  onChange={(e) =>
                                    setValue("lastName", e.target.value)
                                  }
                                  {...register("lastName")}
                                />
                                <div class="invalid-feedback">
                                  Un Nom de famille valide est requis
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="username" class="form-label">
                                  Nom d'utilisateur
                                </label>
                                <div class="input-group has-validation">
                                  <span class="input-group-text">@</span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="username"
                                    placeholder="Username"
                                    required
                                    onChange={(e) =>
                                      setValue("username", e.target.value)
                                    }
                                    {...register("username")}
                                  />
                                  <div class="invalid-feedback">
                                    Votre nom d'utilisateur est obligatoire
                                  </div>
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="email" class="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                  requis
                                  disabled
                                  onChange={(e) =>
                                    setValue("email", e.target.value)
                                  }
                                  {...register("email")}
                                />
                              </div>
                              <div class="col-12">
                                <label for="phone" class="form-label">
                                  Téléphone{" "}
                                  <span class="text-muted">(facultative)</span>
                                </label>
                                <input
                                required
                                  type="phone"
                                  class="form-control"
                                  id="phone"
                                  placeholder="Téléphone personnelle"
                                  onChange={(e) =>
                                    setValue("phoneperso", e.target.value)
                                  }
                                  {...register("phoneperso")}
                                />
                              </div> 
                            </div>
                            
 
                            <hr class="my-4" />
                            <div class="row g-3">
                  <div class="col-12">
                    <label
                      for="addresslocation"
                      class="form-label"
                    >
                      Adresse
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="addresslocation"
                      placeholder="1234 Main St"
                      required
                      onChange={(e) =>
                        setValue(
                          "addresspersolocation",
                          e.target.value
                        )
                      }
                      {...register("addresspersolocation")}
                    />
                    <div class="invalid-feedback">
                      Veuillez entrer une adresse valide pour la
                      livraison / facturation
                    </div>
                  </div>

                  <div class="col-12">
                    <label
                      for="address2location"
                      class="form-label"
                    >
                      Adresse 2{" "}
                      <span class="text-muted">
                        (facultative)
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="address2location"
                      placeholder="Appartement et complément d'adresse"
                      onChange={(e) =>
                        setValue(
                          "complementpersolocation",
                          e.target.value
                        )
                      }
                      {...register("complementpersolocation")}
                    />
                  </div>

                  <div class="col-md-6">
                    <label
                      for="countrylocation"
                      class="form-label"
                    >
                      Pays
                    </label>
                    <select
                      class="form-select"
                      id="countrylocation"
                      required
                      onChange={(e) =>
                        setValue(
                          "countrypersolocation",
                          e.target.value
                        )
                      }
                      {...register("countrypersolocation")}
                    >
                      <option value="">Choisir...</option>
                      <option value="France">France</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select a valid country.
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label
                      for="statelocation"
                      class="form-label"
                    >
                      Etat / région
                    </label>
                    <select
                      class="form-select"
                      id="statelocation"
                      required
                      onChange={(e) =>
                        setValue(
                          "statepersolocation",
                          e.target.value
                        )
                      }
                      {...register("statepersolocation")}
                    >
                      <option value="">Choisir...</option>
                      <option value="France">
                        France Métropolitaine
                      </option>
                      <option value="île de la Réunion">
                        Île de la Réunion
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label
                      for="citylocation"
                      class="form-label"
                    >
                      Ville
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="citylocation"
                      name="citylocation"
                      required
                      onChange={(e) =>
                        setValue(
                          "citypersolocation",
                          e.target.value
                        )
                      }
                      {...register("citypersolocation")}
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="ziplocation" class="form-label">
                      Code postale
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="ziplocation"
                      maxLength={5}
                      placeholder=""
                      required
                      onChange={(e) =>
                        setValue(
                          "zipcodepersolocation",
                          e.target.value
                        )
                      }
                      {...register("zipcodepersolocation")}
                    />
                    <div class="invalid-feedback">
                      Zip code required.
                    </div>
                  </div> 
                </div> 
                <hr class="my-4" />

                            <button
                              class="w-100 btn btn-dark btn-lg"
                              type="submit"
                              disabled={loadingForm1}
                            >
                              {loadingForm1
                                ? "Chargement ...."
                                : "Enregistrer et mettre à jour"}
                            </button>
                          </form>
                        )}
                      </div>
                    
                     </div>  

     </div>



       
  </div>
  </>
  )};
  export default MyAccount;
  