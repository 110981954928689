import React , { useEffect, useState } from "react"; 
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ClientsAdmin = () => { 

    const db = firebase.firestore();
    const history = useHistory();

    const [loadingUser, setLoadingUser] = React.useState(false);
    
  const [l, setL] = useState(false)

  const [products, setData] = useState([]);
    const [user, setUser] = React.useState(null); 
    const [myOrders, setMyOrders] = useState([]);

    const getUser = async (uid, cb) => {
        await firebase.firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then(async (docd) => {
            var user = docd.data();
            user.id = docd.id;
            cb(user);
          })
          .catch((error) => {
            cb(null);
          });
          
      };

    const getUsers = async () => { 
        await firebase.firestore()
        .collection("users")  
        .get()
        .then(async (snapshots) => {
            let data = [];
            snapshots.forEach(d => {
                var ds = d.data();
                ds.id = d.id;
                data.push(ds);
            })
            if (data.length > 0){
                setData(data);
                setL(false);
            }
        }).catch(() => {
            setL(false);
        })
        let orders =[]
        await firebase.firestore()
              .collection("orders")   
              .get()
              .then(async (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  var order = doc.data();
                  order.id = doc.id;
                  orders.push(order)
                })
                console.log('orders', orders.userId)
                setMyOrders(orders);
              })
              .catch((error) => { 
                setMyOrders(orders);
              });
    }

    const getTotalPrice = (uid) => {
      let orders = getOrder(uid);
      let price = 0;
      orders?.forEach(o => {
        price += o.amount;
      })
      return Number(price / 100).toFixed(2)
    }
    const getOrder = (uid) => {
      let nb = []
      myOrders?.forEach(element => {
        if (element.userId  === uid){
          nb.push(element)
        }
      });
      return nb 
    }

  useEffect(()=>{
    setL(true)
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        setLoadingUser(true);   
          getUser(u.uid, async (user) =>  { 
            setUser(user);
            firebase.auth().languageCode = "fr";  
            if (user.isAdmin === true){ 
              getUsers()
               setLoadingUser(false);
            }else{
              history.push("/");
              toastr.error("Vous n'avez pas le droit d'accès a cette page.")
            }
          });
        
        
      } else {
        setLoadingUser(false);
        history.push("/");
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);

  }, [])
 

return ( 
    <>
    <div class="container">
    <h1 class="mt-5">{"Administration des clients"}</h1> 
    <div class="row mb-5" style={{textAlign:"left"}}>
    {l ? (
        <p>Chargement ...</p>
    ): (
<div class="table-responsive">
        <table class="table table-striped table-sm">
                        {products?.length > 0 && (
                          <thead>
                            <tr>
                              <th scope="col">#</th> 
                              <th scope="col">Nom Prénom</th>
                              <th scope="col">Email</th> 
                              <th scope="col">Type</th>
                              <th scope="col">Téléphone</th> 
                              <th scope="col">Nbr de commande</th>
                              <th scope="col">Total dépensé</th>
                            </tr>
                          </thead>
                        )} 
                        <tbody> 
                        {
                            products?.map((p) => {
                                return (
                                    <> 
                                         <tr>
                                            <td>#{p.id} </td>
                                            <td>{p.displayName}</td>
                                            <td><a href={`mailto:${p.email}`}>{p.email}</a></td>
                                            <td><h6><Badge   bg={p.isAdmin ? "danger" : "info"}>{p.isAdmin ? 'Admin': 'Client'}</Badge></h6></td>
                                            <td><a href={`tel:${p.phoneperso}`}>{p?.phoneperso}</a></td>
                                            <td>{getOrder(p.id).length}</td>
                                            <td>{getTotalPrice(p.id)} €</td>
                                        </tr> 
                                    </>
                                )
                            })
                        }
                           
                        </tbody>
                      </table>
                    </div>
    )}
    
    
     


       </div>
  </div>
  </>
  )};
  export default ClientsAdmin;
  