import Footer from "./Footer";
import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import NavBar from "./Navbar";

const Layout = ({ children }) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    <NavBar/>
      <div
        style={{
          marginTop: size.width > 800 ? 70 : "4rem",
          // maxWidth: size.width,
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      <CookieConsent
        location="bottom"
        buttonText="J'ai compris"
        cookieName="personprintcookiesconcent"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#fff",
          color: "#4e503b",
          fontSize: "13px",
        }}
        expires={150}
      >
        Ce site utilise des cookies pour améliorer l'expérience utilisateur.
      </CookieConsent>
      <Footer />
    </>
  );
};

export default Layout;
