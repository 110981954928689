import React, { useEffect } from "react";
import "../App.css";
import { useForm } from "react-hook-form";
import firebase from "../firebase";
import { useHistory } from "react-router-dom"; 


 
  const HeaderLogin = () => {
    const history = useHistory();
  const [logout, setAlertLogout] = React.useState(
    history.location.state?.logout || false
  );
  const [deleteU, setAlertDelete] = React.useState(
    history.location.state?.delete || false
  );
  const [forgetPass, setForgetPass] = React.useState(
    history.location.state?.forgetpass || false
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  firebase.auth().languageCode = "fr";

  const redirection = (user) => {
    const redi = localStorage.getItem("redirectAfterlogin");
    if (redi) {
      window.location.href = redi;
      localStorage.removeItem("redirectAfterlogin");
      return;
    }

    switch (user?.role) {
      // case "vendor":
      //   history.push("/tableau-de-bord#dashboard");
      //   break;
      // case "pendingvendor":
      //   history.push("/tableau-de-bord#becomevendor");
      //   break;

      default:
        // redirection à l'accueil
        history.push("/");
        break;
    }
  };

  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        console.log("error", error);
        cb(null);
      });
  };

  useEffect(() => {
    // check if user is login
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          firebase.auth().languageCode = "fr";
          redirection(user);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
      setTimeout(() => {
        setAlertLogout(false);
        setAlertDelete(false);
        setForgetPass(false);
      }, 5000);
    }, 5000);
  }, []);

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const logIn = (value) => {
    if (loading) {
      return;
    }
    setLoading(true);
    firebase.auth()
      .signInWithEmailAndPassword(value.mail.trim(), value.password.trim())
      .then((res) => {
        setLoading(false);
        //redirection automatique
      })
      .catch((err) => {
        setLoading(false);
        //do something with the error
        console.log(err.code);
        // alert(getTranslation(err.code));
        alert("Identifiant invalide");
      });
  };
    return (
        <>
        <div class="b-example-divider"></div>

  <div class="container col-xl-10 col-xxl-8 px-4 py-5">
    <div class="row align-items-center g-lg-5 py-5">
      <div class="col-lg-7 text-center text-lg-start">
        <h1 class="display-4 fw-bold lh-1 mb-3">Vous avez déjà un compte</h1>
        <p class="col-lg-10 fs-4">Connectez-vous directement à votre compte, visualiser vos commandes, leurs statuts.</p>
      </div>
      <div class="col-md-10 mx-auto col-lg-5">
        <form class="p-4 p-md-5 border rounded-3 bg-light"  onSubmit={handleSubmit(logIn, (err) => {
                console.log(err);
              })}>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" 
                  required
                  onChange={(e) => setValue("mail", e.target.value)}
                  {...register("mail")}/>
            <label for="floatingInput">Adresse email</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Mot de passe" 
                  required
                  onChange={(e) => setValue("password", e.target.value)}
                  {...register("password")}/>
            <label for="floatingPassword">Mot de passe</label>
          </div> 
          <button class="w-100 btn btn-lg btn-primary" disabled={loading}style={{background:"#ff00ad", borderColor:'black'}} type="submit">Connexion</button>
          <hr class="my-4"/>
          <small class="text-muted">En cliquant sur Connexion, vous acceptez les conditions d'utilisation.</small>
        </form>
      </div>
    </div>
  </div></>
    );
};

export default HeaderLogin;