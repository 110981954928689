/* eslint-disable no-restricted-globals */
import { useHistory } from "react-router-dom";
import React, { useRef, useState, useEffect,Dimension }  from "react"; 
import firebase from "../firebase"; 
import { Cropper } from "react-advanced-cropper";
import { useForm } from "react-hook-form"; 
import Compressor from "compressorjs";
import DeviceDetector from "device-detector-js";
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "../App.css"; 
import "react-advanced-cropper/dist/style.css";
import "../cropped.css";  
import { toastr } from "react-redux-toastr";
import Sticky from "../components/Sticky";
const storage = firebase.storage();
 
const Load = ({ navigation }) =>{
  // "firebase": "^8.1.2",
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);

  const history = useHistory();
  // let parser = new UAParser("user-agent"); // you need to pass the user-agent for nodejs
 
    const upName = history.location.state?.upName|| null
    const [product, setProduct] = useState( history.location.state?.product || null)
    const [images, setImages] = useState(history.location.state?.dataphotos || []);
    const [imageURLS, setImageURLs] = useState(history.location.state?.photos || []); 
    const [resultUrls, setRsultatUrl] = useState([]);
    const [reload, setReload]  = useState(false);
    const [index, setIndex] = useState(0);
    const [reloadq, setReloadQ] = useState(false);
    const inputRef = useRef(null);
    const cropperRef = useRef(null);
    const [image, setImage] = useState(null);
    const [coordinates, setCoordinate] = useState(null);
    const [coordinatesp, setCoordinateP] = useState(null);
    const [loadingCart, setLoadingCart] = useState(false);
    const [sendct, setSendCt] = useState(0);
    const [loading, setLoading] = useState(false);
    const [preloading, setLoadingPre] = useState(false);
    const [preloadingAdd, setLoadingPreAdd] = useState(false);
    const [preloaddAddmax, setPreloadingAddmax] = useState(0);
    
    const [loadIndex, setLoadIndex] = useState(0) 
    const [l, setL] = useState(0);
    const [h, setH] = useState(0);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


    const { register, handleSubmit, setValue } = useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: false,
      shouldUseNativeValidation: false,
      delayError: undefined,
    });
 

    const pixel = 37.79527559055; 
    async function attendreIsLoaded() {
      while (cropperRef.current.isLoading() || !cropperRef.current.isLoaded()  ) {
          await new Promise(resolve => setTimeout(resolve, 100)); // Attendre 100 millisecondes avant de vérifier à nouveau
      }
  }
  const timer = ms => new Promise(res => setTimeout(res, ms))

 
     
    const prepareNewImages = async (imgs, total) => {
      let ni = [];
      setLoadingPre(true);    
      setLoadingPreAdd(true)
      setPreloadingAddmax(imgs.length)
      // console.log('l',l)
      // console.log('h',h) 
          if (imgs.length > 0) {
            setLoadIndex(0);
            for(let i = 0; i < imgs.length ; i++) { 
              // console.log('ici', i)
              
              await timer(250)
              setImage(imgs[i].defaultimage);  
              // console.log(i, imgs[i].defaultimage)
              await timer(250)
              await attendreIsLoaded(); 
              const cropper = cropperRef.current;
              if (cropper) {
                let co = cropper.getCoordinates();
                // console.log('co', co)
                // console.log(cropper.getState().imageSize)

                cropper.setCoordinates(({ imageSize }) => ({
                  width: imageSize.width,
                  height: imageSize.height
                }))
                // cropper.reset() 
                // await attendreIsLoaded();
                const canvas = cropper.getCanvas(); 
                // let ac = cropper.defaultSize
                // console.log('ac', ac)
                // console.log('cropper', cropper)  
                // console.log("new", cropper.getCoordinates())
                // console.log("resultat", canvas?.toDataURL())


  

                ni.push({
                  resultat: canvas?.toDataURL() ,
                  coordinates: cropper.getCoordinates(),
                  quantity: 1,
                  option: null,
                  index: 0,
                  defaultimage:imgs[i].defaultimage,
                  file:imgs[i].file,  
                  delete:false, 
                  angle: product.config.l * pixel * 60
                })   
                // console.log('load', i+1)
                setLoadIndex(i+1) 
              } 
              if (i + 1 === imgs.length){
                // fini
                if (total?.length > 0){
                  total?.map((t, ins) => {
                    let m = t;
                    m.index = i + 1 + ins
                    ni.push(m)
                  })

                  setImageURLs(ni);  
                    setRsultatUrl(ni); 
                    if (ni[0]) {
                      await timer(300)
                      await attendreIsLoaded();
                        setImage(URL.createObjectURL(ni[0].file));
                        setIndex(0);
                        setValue("quantity", ni[0].quantity) 
                        setCoordinate(null) 
                        setCoordinateP(null)
                        
                        setLoadingPre(false);
                        setLoadingPreAdd(false)
 
                    } 
              
                }
              }
            } 
          }  
           
            
    }
      
    const prepareAllImages = async () => {
      let ni = [];
      setLoadingPre(true);  
      // let lasti = null;
      if (images.length > 0){


        for(let i = 0; i < images.length ; i++) { 
          
          await timer(250)
          setImage(URL.createObjectURL(images[i])); 
          // console.log(URL.createObjectURL(images[i]))
          await timer(250)
          await attendreIsLoaded(); 
          // console.log("cropper est chargé, continuons...");
          const cropper = cropperRef.current;
          if (cropper) { 
            const canvas = cropper.getCanvas();  
            // console.log("fez", canvas?.toDataURL()) 
            ni.push({
              resultat: canvas?.toDataURL() ,
              coordinates: cropper.getCoordinates(),
              quantity: 1,
              option: null,
              index: i,
              defaultimage:URL.createObjectURL(images[i]),
              file:images[i],  
              delete:false, 
              angle: l === product.config.l * pixel * 60 ? product.config.h * pixel * 60 : product.config.l * pixel * 60
            }) 
            setImageURLs(ni);  
            setRsultatUrl(ni); 
            setLoadIndex(i+1) 
          }
            if (ni.length === images.length){  
              if (images[0]) {
                await timer(300)
                await attendreIsLoaded();
                  setImage(URL.createObjectURL(images[0]));
                  setIndex(0);
                  setCoordinate(null) 
                  setCoordinateP(null) 
                  setLoadingPre(false);
                 
              }
            }
        } 
      }
    }
    
    useEffect(()=>{
      if (product){
        setL(product.config.l * pixel * 60);
        setH(product.config.h * pixel * 60);
      }
    }, [])

    function makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
  }
   

    useEffect(()=>{
      if (imageURLS.length > 0){
        let data = [];
        imageURLS.forEach((e, i) => {
          // console.log("defaultimage", e)
          // console.log("file", images[i])
          data.push({
            index: i,
            defaultimage:e,
            file:images[i],
            resultat: null,
            coordinates:null,
            quantity: 1,
            delete:false
          })
        });
        setRsultatUrl(data); 
      }
    }, [])

    const rotate = (angle) => {
      setReloadQ(true)
      if (cropperRef.current) {  
        if (l === product.config.l * pixel * 60){
          setH(product.config.l * pixel * 60);
          setL(product.config.h * pixel * 60); 
          // setCoordinate(e.coordinates || null)
        }else{
          setL(product.config.l * pixel * 60);
          setH(product.config.h * pixel * 60); 
          // setCoordinateP(e.coordinatesP || null)
        }
      }
      setTimeout(() => {
        setReloadQ(false)
      }, 100);
  };

    const onCrop = (value) => {
      // event.preventDefault();
      setLoading(true);
      const cropper = cropperRef.current;
      if (cropper) {
        const canvas = cropper.getCanvas();
       let sd =  resultUrls;
       sd[index].resultat = canvas.toDataURL();
       if (l === product.config.l * pixel * 60){
        sd[index].coordinates = cropper.getCoordinates()
       }else{
        sd[index].coordinatesP = cropper.getCoordinates()
       }
      //  console.log("value", value)
       sd[index].quantity = value.quantity;
       sd[index].option = value.option || null; 
      //  console.log("l === product.config.l * pixel * 60 ? product.config.l * pixel * 60 : product.config.h * pixel * 60", l === product.config.l * pixel * 60 ? product.config.h * pixel * 60 : product.config.l * pixel * 60)
       sd[index].angle = l === product.config.l * pixel * 60 ?product.config.l * pixel * 60 :  product.config.h * pixel * 60 
       setImageURLs(sd);
       setTimeout(() => {
        setLoading(false);
        toastr.success('', 'Terminé !');
       }, 200);
        // const newTab = window.open();
        // if (newTab && canvas) {
        //   newTab.document.body.innerHTML = `<img src="${canvas.toDataURL()}"></img>`;
        // }
      }
    };

    function canAddToCart() {
      var can = true;
      var tab = [];
      if (resultUrls && resultUrls.length > 0){
        resultUrls.forEach((d) => {
          if (!d.delete){
            tab.push(d);
          }
        })
        tab.forEach(g => {
          if (g.resultat === null && g.quantity > 0) can = false;
        })

      }else{
        can = false
      }
      return true //can;
    }

    const getUser = async (uid, cb) => {
      await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then(async (docd) => {
          if (docd.exists){
            var user = docd.data();
            user.id = docd.id;
            cb(user);
          }else{
            cb(null)
          }
        })
        .catch((error) => {
          console.log("error", error);
          cb(null);
        });
    };

    const getPanier = async (uid, cb) => {
      await firebase.firestore()
        .collection("carts")
        .doc(uid)
        .get()
        .then(async (docd) => {
          if (docd.exists){
            var cart = docd.data();
            cart.id = docd.id;
            cb(cart);
          }else{
            cb(null)
          }
        })
        .catch((error) => {
          console.log("getPanier error", error);
          cb(null);
        });

    }


    const addProductIncart = async (cartId,cart, user,products,cb) => {
      let data = cart.products;
      let continu = true;

      const uid = cartId;
      let total  = 0;
          resultUrls.forEach(d => {
            if (!d.delete){
              total += 1
            }
          })
          let int = 0
      // products.forEach(d => {
        for (let index = 0; index < resultUrls.length; index++) {
          let d = resultUrls[index]
      // for(let i = 0; i < products.length ; i++) {  
        if (!d.delete){
          let filename = `user/${uid}/carts/q_${d.quantity}-${data.length}-${makeid(5)}.jpg`;
          
          // console.log(d);
          let s = {}; 
          s.coordinates = d.coordinates; 
          s.index = data.length;
          s.quantity = d.quantity;
          s.option = d?.option || null
          s.resultat = filename;
          s.delete = false;
          s.type = product;
          s.upName =upName;
          s.title = product?.title; 

          await timer(50);
          uploaded(d.resultat, filename, async (r) => {
            if (r === 0){
              continu = false;
            }else{
              int += 1
              data.push(s);
              setSendCt(int)
            }
            if (int === total){
              if (!continu){
                setLoadingCart(false);
                alert("Un erreur s'est produite lors de l'envoie d'un fichier, réessayer plus tard.");
                cb(null);
                return;
              }
              // console.log(data);
              await firebase.firestore()
                .collection("carts")
                .doc(cartId)
                .update({products: data, user: user})
                .then(async () => {
                  cb(true);
                })
                .catch((error) => {
                  console.log("addProductIncart error", error);
                  cb(null);
                });
            }
          })
            
        }
      }//);
      
    }
  

     function dataURLtoFile(dataurl, filename) {
      var arr = dataurl?.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
      // const resizedImage = await sharp(new File([u8arr], filename, {type:mime}))
      // .resize({ density: 300 })
      // .toBuffer();
      // console.log('resizedImage', resizedImage);
      // return resizedImage;
  }
  
  //Usage example:
  // console.log(file);

    const uploaded = (data,title, cb) => {

      //get User

      const storageRef = storage.ref(title); 
      var file = dataURLtoFile(data,title?.split('/')[title?.split('/').length - 1]);
      
      try {
        new Compressor(file, {
          mimeType:'image/jpeg',
          convertirTypes: ['image/png', 'image/webp', 'image/jpeg'],
          convertSize: 250000,
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            storageRef.put(compressedResult).then((snapshot) => {
              // console.log('Uploaded a data_url string!', snapshot);
              cb(1)
            }).catch(err => {
              toastr.warning('Erreur', err)
              console.error("sendfile", err);
              console.error('Une erreur s\'est produite lors de l\'envoie du fichier');
              cb(0)
            }); 
  
          },
          error: (error) => { 
            storageRef.put(file).then((snapshot) => {
              // console.log('Uploaded a data_url string!', snapshot);
              cb(1)
            }).catch(err => {
              toastr.warning('Erreur', err)
              console.error("sendfile", err);
              console.error('Une erreur s\'est produite lors de l\'envoie du fichier');
              cb(0)
            }); 
          }
        });
      } catch (error) {
        storageRef.put(file).then((snapshot) => {
          // console.log('Uploaded a data_url string!', snapshot);
          cb(1)
        }).catch(err => {
          toastr.warning('Erreur', err)
          console.error("sendfile", err);
          console.error('Une erreur s\'est produite lors de l\'envoie du fichier');
          cb(0)
        }); 
      }
      
      
    }

    const pepa = (uid, user = null) => {
      getPanier(uid, async (cart)  =>  {
        if (cart){
          setLoadingCart(true)
          // console.log("cart",cart );
          // Ajouter les produits au panier
          addProductIncart(uid, cart, user, resultUrls, (res) => {
            if (res){
              // console.log("addProductIncart update", res)
              // alert("Commande ajoutée au panier");
              setLoadingCart(false)
              history.push("/panier");
            }else{

              setLoadingCart(false)
              alert("une erreur s'est produite")
            }
          });
        }else{ 
          // Créer le panier et y ajouter les produits
          let ps = []
          let continu = true;
          // const uid = firebase.auth().currentUser.uid;
          let total  = 0;
          resultUrls.forEach(d => {
            if (!d.delete){
              total += 1
            }
          })
          let int = 0
          // resultUrls.forEach(d => {
            for (let index = 0; index < resultUrls.length; index++) {
               let d = resultUrls[index]
              
            if (!d.delete){

              let filename = `user/${uid}/carts/q_${d.quantity}-${ps.length}-${makeid(5)}.jpg`


              // console.log("ici", d);
              let s = {}; 
              s.coordinates = d?.coordinates; 
              s.coordinatesP = d?.coordinatesP !== undefined ? d?.coordinatesP : null; 
              s.index = ps?.length || index;
              s.quantity = d?.quantity || 0;
              s.resultat = filename;
              s.option = d?.option || null;
              s.delete = false;
              s.type = product;
              s.upName = upName;
              s.title = product?.title;
              
              await timer(50);

              uploaded(d.resultat, filename, async  (r) => { 
                if (r === 0){
                  continu = false;
                }else{
                  int += 1
                  ps.push(s); 
                 setSendCt(int)
                }
                if (int === total ){ 
                  if (!continu){
                    setLoadingCart(false)
                    alert("une erreur s'est produite loprs de l'envoie d'un fichier, réessayer");
                    return;
                  }
                  console.log("ps" ,ps);
                  setLoadingCart(true)
                  await firebase.firestore().collection("carts").doc(uid).set({user: user, products :ps })
                   .then(() => {
                      // alert("Photos ajoutée au panier");
                      setLoadingCart(false) 
                      history.push("/panier");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                        setLoadingCart(false)
                        alert("une erreur s'est produite")
                    }); 
                }

              })
            }
          }//);
         
        }
      })
    }

    const preada = () => {
      const uid = firebase.auth()?.currentUser?.uid || null
      getUser(uid, (user) => {
        setLoadingCart(true)
        // console.log("user", user);  
        if (user){
          // Get actual panier 
          pepa(uid, user)
        }else{
          // aucun user
        setLoadingCart(false)
          console.log("uid", uid);
          alert("une erreur s'est produite")
        }
         

      });
    }

    const addToCart = () => {
      // ajouter au panier
      // console.log("resultUrls", resultUrls)
      const uid = firebase.auth()?.currentUser?.uid || null
      //get User
      if (uid){
        // console.log('uid', uid);
        confirmAlert({
          title: 'Souhaitez vous valider le cadrage de vos photos ?',
          message: 'Attention, aucunes modifications ou réclamations ne sera possible par la suite.',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                preada()
              }
            },{
              label: 'Non',
              onClick: () => {
              }
            },
          ]
        })
      }else{
        // l'utilisateur n'est pas connecté
        confirmAlert({
          title: 'Souhaitez vous valider le cadrage de vos photos ?',
          message: 'Attention, aucunes modifications ou réclamations ne sera possible par la suite.',
          buttons: [
            {
              label: 'Oui',
              onClick: () => {
                setTimeout(() => {
                  confirmAlert({
                    title: 'Ajouter au panier',
                    message: 'Continuez sans être connecté?.',
                    buttons: [
                      {
                        label: 'Continuer sans compte',
                        onClick: () => {
                          const puid = localStorage.getItem("pre-panier");
                          if (puid) {
                            pepa(puid)
                          }else{
                            const m = `pre-${makeid(10)}`
                            localStorage.setItem("pre-panier",m); 
                            pepa(m)
                          } 
                        }
                      },
                      {
                        label: 'Se connecter / S\'inscrire',
                        onClick: () => {
                          const puid = localStorage.getItem("pre-panier");
                          if (puid) {
                            pepa(puid)
                          }else{
                            const m = makeid(10)
                            localStorage.setItem("pre-panier",m); 
                            pepa(m)
                          }  
                          setTimeout(() => {
                            localStorage.setItem("redirectAfterlogin", window.location.href);
                            history.push('/connexion')
                          }, 5000);
                        }
                      }
                    ]
                  }); 
                }, 500);
              }
            },
            {
              label: 'Non',
              onClick: () => {
              }
            },
          ]
        }); 
        
      }
    }

    // const onLoadImage = (event) => {
    //   const file = event.target.files && event.target.files[0];
    //   if (file) {
    //     setImage(URL.createObjectURL(file));
    //   }
    //   event.target.value = "";
    // };

    useEffect(() => {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      return () => {
        if (image) {
          URL.revokeObjectURL(image);
        }
      };
    }, [image]);

    useEffect(()=> {
      if (images.length > 0){
        if (images[0]) {
          prepareAllImages();
          setImage(URL.createObjectURL(images[0]));
          setIndex(0);
          setCoordinate(null)
          setCoordinateP(null) 
        }
       
      }
    }, [])

    
    function isMobileDevice() {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  function isTablet() {
    // Vérifie si la largeur de l'écran est supérieure à un certain seuil, par exemple 600 pixels
    const screenWidth = window.screen.width;
    const isLargeScreen = screenWidth >= 600;

    // Vérifie si le User-Agent contient des chaînes de caractères typiquement associées aux tablettes
    const isTabletUA = /iPad|Android|tablet|kindle/i.test(navigator.userAgent);

    // Retourne vrai si l'écran est large et que le User-Agent indique une tablette
    return isLargeScreen && isTabletUA;
}


    return (
        <div class="container "> 
        {product && (
          <div class="product-wrap clearfix product-deatil">
            <div class="row">
              {preloading && (<>
                <p style={{paddingTop:350}}>Chargement et redimensionnement automatique des images {loadIndex}/{preloadingAdd ? preloaddAddmax : images.length}</p>
                <div style={{width:'95%',margin:'auto'}}>
                <ProgressBar animated variant="success" now={(loadIndex/(preloadingAdd ? preloaddAddmax :images.length))*100} style={{marginBottom:700, padding:0}} />
                </div>
              </>) } 
              <div style={{opacity: preloading? 0: 1, height: preloading ? 1: null}} class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
                <div className="row"  style={{padding:10}}>
                  <h2 class="name"> 
                  {upName} {product.title}
                  </h2>{
                    canAddToCart() &&  <button disabled={loadingCart} class="btn btn-success" onClick={addToCart} >{loadingCart ? `Chargement (${sendct}/${resultUrls.length})`: "Ajouter au panier"}</button>
                  }
                </div> 
               <div class="container " className="hide-768" >
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 py-3">
                  {/* web version */}
                  {!loadingCart && (
                    <>
                      {!reload ? product.price && <label for="file-upload" class="btn btn-success" style={{

                      border:" 1px solid #ccc",
                      display: "inline-block",
                      padding: "6px 12px",
                      cursor: "pointer"
                                      }} >
                          Choisir des fichiers
                      </label> : (<p>Chargement ...</p>)} 
                    </>
                  )}
                   

                      {product.price && <input id="file-upload" multiple accept="image/*" onChange={(e) => {
                      let ims = [];  
                      setReloadQ(true);
                        setReload(true);
                      for (const [key, value] of Object.entries(e?.target?.files)) {
                        ims.unshift({defaultimage:URL.createObjectURL(value), file:value});
                      }  

                      setL(product.config.l * pixel * 60);
                      setH(product.config.h * pixel * 60);
                      setTimeout(() => { 
                        setReloadQ(false); 
                        if (ims?.length > 0){
                          prepareNewImages(ims, resultUrls) 
                        } 
                        setReload(false); 
                      }, 1000); 

                  }} type="file" style={{display: "none"}}/> }

                    {!reload && resultUrls && resultUrls.map((e, i) => (
                      <a onClick={() => {
                        if (!e.delete){ 
                          setImage(URL.createObjectURL(e.file));
                          setIndex(i);
                          setValue("quantity", e.quantity)
                          // console.log("e", e)
                          setCoordinate(e.coordinates || null)
                          setCoordinateP(e.coordinatesP || null)
                          if (product.config.l * pixel * 60 === e?.angle){  
                            setL(product.config.l * pixel * 60);
                            setH(product.config.h * pixel * 60); 
                          }else{   
                            setH(product.config.l * pixel * 60);
                            setL(product.config.h * pixel * 60);  
                          } 
                        }
                      }}>
                        <div style={{position:"relative", opacity: e.delete ? 0.3 : 1  }}>
                          <img src={e.resultat ? e.resultat : e.defaultimage} alt="not fount" width={"100%"} />
                          <p style={{
                            position: "absolute",
                            backgroundColor:e?.resultat ? "green":"pink",
                            width: 35,
                            height: 35,
                            color:e?.resultat ? "white": "black",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center", 
                            display: "flex",
                            borderRadius: 17.5,
                            fontWeight: "bold", top:-5, left:-5
                          }}>x{e.quantity}</p>
                          <a 
                          onClick={() => {
                            const cropper = cropperRef.current;
                            if (cropper) {
                              setLoading(true); 
                              let sd =  resultUrls;
                              sd[i].quantity = 0;
                              sd[i].delete = true;
                              setImageURLs(sd);
                              setTimeout(() => {
                                setLoading(false);
                              }, 200);
                            }
                          }}
                            src=""
                            style={{
                            position: "absolute",
                            backgroundColor:"red",
                            width: 35,
                            height: 35, 
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center", 
                            display: "flex",
                            borderRadius: 17.5, 
                            bottom:-5, right:-5
                          }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                        </svg>
                            
                            </a>
                        </div>
                      </a>
                    ))}
                    </div>
                  </div> 
                
                 
              </div>
    {/* mobile version */}
    <div style={{opacity: preloading? 0: 1, height: preloading ? 1: null,padding:0,marginLeft:10, marginRight:10}} className="hm-768"  >
      <div class="scrollable-div">
        {!loadingCart && (
                      <>
          {!reload ? product.price && <label for="file-upload" class="btn btn-success" style={{

                      border:" 1px solid #ccc",
                      display: "inline-block",
                      padding: "6px 12px",
                      cursor: "pointer"
                                      }} >
                          Choisir des fichiers
                      </label> : (<p>Chargement...</p>)} 

                      {product.price && <input id="file-upload" multiple accept="image/*" onChange={(e) => {
                          let ims = [];  
                          setReload(true);
                          setReloadQ(true);
                        for (const [key, value] of Object.entries(e?.target?.files)) {
                          
                          ims.unshift({defaultimage:URL.createObjectURL(value), file:value});
                          
                        }  
                        setL(product.config.l * pixel * 60);
                        setH(product.config.h * pixel * 60);
                        setTimeout(() => { 
                          setReloadQ(false);
                          // selectionné la premiere image 
                          // setImage(ims[0]?.defaultimage);
                          // setIndex(0);
                          // setCoordinate(null)
                          // setCoordinateP(null) 
                          if (ims?.length > 0){
                            prepareNewImages(ims, resultUrls) 
                          } 
                          setReload(false); 
                        }, 1000); 

                  }} type="file" style={{display: "none"}}/> }
              </>
        )}
                  {!reload && resultUrls && resultUrls.map((e, i) => (
                    <a onClick={() => {
                      if (!e.delete){ 
                        setImage(URL.createObjectURL(e.file));
                        setIndex(i);
                        setValue("quantity", e.quantity); 
                        setCoordinate(e.coordinates || null)
                        setCoordinateP(e.coordinatesP || null)
                        if (product.config.l * pixel * 60 === e?.angle){  
                          setL(product.config.l * pixel * 60);
                          setH(product.config.h * pixel * 60); 
                        }else{   
                          setH(product.config.l * pixel * 60);
                          setL(product.config.h * pixel * 60);  
                        } 
                      }
                    }}>
                      <div className="hj" style={{position:"relative", opacity: e.delete ? 0.3 : 1  }}>
                        <img src={e.resultat ? e.resultat : e.defaultimage} alt="not fount" height={120} />
                        <p style={{
                          position: "absolute",
                          backgroundColor:e?.resultat ? "green":"pink",
                          width: 35,
                          height: 35,
                          color:e?.resultat ? "white": "black",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center", 
                          display: "flex",
                          borderRadius: 17.5,
                          fontWeight: "bold", top:-5, left:-5
                        }}>x{e.quantity}</p>
                        <a 
                        onClick={() => {
                          const cropper = cropperRef.current;
                          if (cropper) {
                            setLoading(true);
                            const canvas = cropper.getCanvas();
                            let sd =  resultUrls;
                            sd[index].quantity = 0;
                            sd[index].delete = true;
                            setImageURLs(sd);
                            setTimeout(() => {
                              setLoading(false);
                            }, 200);
                          }
                  
                        }}
                          src=""
                          style={{
                          position: "absolute",
                          backgroundColor:"red",
                          width: 35,
                          height: 35, 
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center", 
                          display: "flex",
                          borderRadius: 17.5, 
                          bottom:-5, right:-5
                        }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                      </svg>
                          
                          </a>
                      </div>
                    </a>
                  ))}

      </div>
                </div>
              <div style={{opacity: preloading? 0.1: 1, height: preloading ? 1: null}} className="example col-md-6">
      <div className="example__cropper-wrapper">
        {!reloadq && (
          <Cropper
          style={{height:isMobileDevice() ? windowHeight * 0.35 : windowHeight * 0.6}}
          // onReady={() => {
            // console.log(l === product.config.l * pixel * 60)
            // console.log(coordinates)
            // console.log(coordinatesp)
            // console.log('image', image)
            // console.log("coo", cropperRef.current.getCoordinates())
            // console.log("ll", cropperRef.current)
          //   console.log("ici" , l === product.config.l * pixel * 60 ? coordinates : coordinatesp)
          // }}
          //positionRestrictions={{
          //minWidth: l,maxWidth:l,minHeight: h,maxHeight: h,
          // }} 
            defaultSize={{
              width: l,
              height: h
            }}
            stencilProps={{
              aspectRatio: l/h,
              movable: true,
              resizable: true
          }} 
          defaultPosition={
            l === product.config.l * pixel * 60 ? coordinates : coordinatesp
          } 
            ref={cropperRef}
            className="example__cropper"
            backgroundClassName="example__cropper-background"
            src={image}
          />
        )}
        <a class="btn btn-lg btn-warning mb-3" style={{position:'relative', top:-64}} onClick={() => rotate(-90)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
          </svg></a>
      </div>
      <div className="example__buttons-wrapper"  >


                {/* web version */}
      <form   class=" hide-768 p-4 p-md-5 border rounded-3 bg-light" style={{position:'relative', top:-44}}  onSubmit={handleSubmit(onCrop, (err) => {
                console.log(err);
              })}>
          <div class="row row-cols-2">
          <div class="form-floating mb-3">
            <input type="number" class="form-control" id="quantity"  min="1" defaultValue={1}
                  required
                  onChange={(e) => setValue("quantity", e.target.value)}
                  {...register("quantity")}/>
            <label for="quantity">Quantité</label>
          </div>  
          {(product?.title?.includes('irage') || upName?.includes('irage') || upName?.includes('ormat') || product?.title?.includes('ormat'))  && (<div class="form-floating mb-3">
            <select name="option" class="form-control" id="option-select"
            onChange={(e) => setValue("option", e.target.value)}
            {...register("option")}
            >
              {/* <option value={null}>--Option d'impression--</option> */}
              <option value="Brillant">Brillant</option>
              <option value="Mat">Mat</option>
              <option value="Mat fin">Mat fin</option>
              <option value="Lustré">Lustré</option>
            </select> 

            <label for="option">Option d'impression: (Brillant par défaut)</label> 

          </div> )}
          
          <button class="btn btn-lg btn-success mb-3" type="submit" disabled={loadingCart || loading}>{loading ?"Chargement" : "valider le cadrage"}</button>
          </div> 
          
      </form> 
      {/* mobile version */}
        {
          device.device.type === "smartphone" && (
            <>  
            <div className="hm-768" > 
          {isMobileDevice() && (
            <Sticky> 
              <form onSubmit={handleSubmit(onCrop, (err) => {
                      console.log(err);
                    })}>
              <div class="row row-cols-2">
                <div class="form-floating mb-3">
                  <input type="number" class="form-control" id="quantity"  min="1" defaultValue={1}
                        required
                        onChange={(e) => setValue("quantity", e.target.value)}
                        {...register("quantity")}/>
                  <label for="quantity">Quantité</label>
                </div>  
                {(product?.title?.includes('irage') || upName?.includes('irage') || upName?.includes('ormat') || product?.title?.includes('ormat'))  && (<div class="form-floating mb-3">
                  <select name="option" class="form-control" id="option-select"
                  onChange={(e) => setValue("option", e.target.value)}
                  {...register("option")}
                  >
                    <option value="Brillant">Brillant</option>
                    <option value="Mat">Mat</option>
                    <option value="Mat fin">Mat fin</option>
                    <option value="Lustré">Lustré</option>
                  </select> 

                  <label for="option">Option d'impression: </label> 

                </div> )}
                
                </div> 
                <div class="row row-cols-1">
                <button style={{width: '100px!important'}} class="btn btn-lg btn-success mb-3" type="submit" disabled={loadingCart || loading}>{loading ? "Chargement" : "valider le cadrage"}</button>
                
                </div>
                
              </form>
            </Sticky>
          )} 
            </div>
            </>
          )
        }
        
        </div>
      </div> 
            </div>
          </div>
        )} 
      </div>
    )
}
export default Load;