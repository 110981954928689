import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import 'bootstrap/dist/css/bootstrap.min.css'; 


const STRIPE_PK = "pk_live_51DYygZHKNIdygvuQfapHDILCsMAIBdW7q5y6yoLjtboAvrOYxiN6tL1ZHksFfYwGTiRnafL0Xlp3BwyX4ruAjWxR00LLfr73ug"//"pk_test_aRHx5mse9Y6hBWLYZS1sfVFB"; 
const stripePromise = loadStripe(STRIPE_PK);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
  <React.StrictMode>
  <Elements stripe={stripePromise} >
      <App />
    </Elements>
    </React.StrictMode>
  </Router>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
