import React, { useEffect } from "react";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Col from 'react-bootstrap/Col'; 
import Image from 'react-bootstrap/Image'; 
import ReactN from "reactn";
import logo from '../images/logo.png'
import av from '../images/user-avatar.png'
import { Badge } from "react-bootstrap";

function NavBar() {

  const [loadingUser, setLoadingUser] = React.useState(false);
  const [isLog, setIsLog] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [menus, setMenus] = ReactN.useGlobal('menus'); 
  const history = useHistory();


  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };

  const getMenu = async () => { 
    const me = JSON.parse(localStorage.getItem("menu"));
    if (me) {
      if (me?.length > 0){
        setMenus(me)
      }
      
    }
    await firebase.firestore()
      .collection("menu") 
      .orderBy("order")
      .get()
      .then( (snapshots) => {
        let data = [];
        snapshots.forEach(d => {
          var ds = d.data();
          ds.id = d.id;
          ds.sub = ds?.sub?.sort((a, b) => a.order - b.order);
          data.push(ds);
        })
        console.log('data menu', data);
        setMenus(data);
        localStorage.setItem("menu",JSON.stringify(data));  
      })
      .catch((error) => {
        alert("une erreur s'est produit pour récuprerer les données du menu.")
      });
  }

  useEffect(() => {

    getMenu()
    // check if user is login
    setLoadingUser(true);
    
    const use = JSON.parse(localStorage.getItem("use"));
    if (use?.uid){
      // console.log("use", use);  
          setUser(use);
          firebase.auth().languageCode = "fr"; 
          setLoadingUser(false);
          setIsLog(true);
    }
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          localStorage.setItem("use",JSON.stringify(user));  
          setUser(user);
          firebase.auth().languageCode = "fr"; 
          setLoadingUser(false);
          setIsLog(true);
        });
      } else {
        setLoadingUser(false);
        localStorage.setItem("use",JSON.stringify(null));
      }
    });
    setTimeout(() => {
      setLoadingUser(false); 
    }, 5000);
  }, []);

  const logOut = () => {
    firebase.auth()
      .signOut()
      .then((res) => {
        history.push({ pathname: "/connexion", state: { logout: true } });
        //do something else with res
      })
      .catch((err) => {
        alert("Une erreur s'est produite, réessayer plus tard.");
      });
  };

  return (
    <>
<Navbar expand="lg" className="site-header fixed-top ">
      <Container>
        <Navbar.Brand href="/"><img class="logo img-responsive" src={logo} alt="zepixbox" style={{height:30}}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          {menus?.length > 0 && 
            menus?.map((d) => {
             return (<>
             {d.sub ? (
             <NavDropdown title={d.title} id={d.slug}>
                {
                  d.sub.map((s) => {
                    return (<NavDropdown.Item href={`/c/${s.slug}`}>{s.title}</NavDropdown.Item> )
                  })
                }
              </NavDropdown>
              ): (<Nav.Link href={`/c/${d.slug}`}>{d.title}</Nav.Link>)}
            </>)
          })
        }
          

            {/* <NavDropdown title="Poster" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Poster photo classique</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Poster photo retro</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Poster pêle mêle</NavDropdown.Item>
            </NavDropdown> */}
            {/* <NavDropdown title="Photo magnetique" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Magnet 10x20cm</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Magnet 10x15cm</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Magnet 5x15cm</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Magnet carré</NavDropdown.Item>
            </NavDropdown>  */}
            {/* <NavDropdown title="Photos sur toile" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">20x30cm</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">30x30cm</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">30x40cm</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">40x60</NavDropdown.Item>
            </NavDropdown>  */}
            {/* <Nav.Link href="/c/chromalux">Photos chromalux</Nav.Link>          */}
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {!loadingUser && (
          <>
          {!isLog && ( <Nav.Link href="/connexion">Connexion</Nav.Link>)}
           
            <Nav.Link href="/panier" style={{marginRight:30, marginLeft:30}}><i class="fa fa-shopping-cart"/> </Nav.Link>
            {isLog && ( <NavDropdown title={<Col xs={6} md={4} style={{margin:"auto", left:'-220px!important'}}>
              <Image src={av} style={{width:25}} roundedCircle />
            </Col>} id="basic-nav-dropdown">
              <NavDropdown.Item href="/moncompte">Mon compte</NavDropdown.Item>
              <NavDropdown.Item href="/commandes">
                Mes commandes
              </NavDropdown.Item>
              {
                user?.isAdmin === true && (
                  <>
                  <hr/>
                  <p style={{paddingLeft:10}}><Badge>
                    Admin
                  </Badge></p>
                  <NavDropdown.Item href="/admin">
                    Administration des commandes
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/admin/products">
                  Gestion des produits
                </NavDropdown.Item> 
                <NavDropdown.Item href="/admin/clients">
                    Gestion des clients 
                </NavDropdown.Item>
                <NavDropdown.Item href="/admin/codepromo">
                    Gestion des code promotionnels <Badge bg="success">New</Badge> 
                </NavDropdown.Item>
                  <hr/>
                </>
                )
              }
              {/* <NavDropdown.Item href="#action/3.3">Mes packs</NavDropdown.Item>
              <NavDropdown.Divider /> */}
              <NavDropdown.Item href="#action/3.4" onClick={logOut}>
                Déconnexion
              </NavDropdown.Item>
            </NavDropdown> )}
             
             </>
          )}
        
        </Navbar.Collapse>
      </Container>
    </Navbar>
</>
   
  );
}

export default NavBar;