import React, { useEffect } from "react";
import "../App.css";
import { useForm } from "react-hook-form";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";

const Action3 = () => {

  const puid = localStorage.getItem("pre-panier");
               

  const history = useHistory();
  const [logout, setAlertLogout] = React.useState(
    history.location.state?.logout || false
  );
  const [deleteU, setAlertDelete] = React.useState(
    history.location.state?.delete || false
  );
  const [forgetPass, setForgetPass] = React.useState(
    history.location.state?.forgetpass || false
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  firebase.auth().languageCode = "fr";

  const redirection = (user) => {


    if (puid) {
      // on a un panier en attente 
       getPanier(puid, (panier) =>{
        if (panier){ 
            let u = user
            u.id = user.id;
            user.uid = user.id
            let ps = panier;
            ps.id = user.id
            ps.user = u;
            firebase.firestore().collection("carts")
            .doc(user.id)
            .set(ps).then(( ) => { 
              firebase.firestore().collection("carts").doc(puid).delete()
              localStorage.removeItem("pre-panier");
              console.log('item remove','pre-panier') 
              const redi = localStorage.getItem("redirectAfterlogin");
              if (redi) {
                window.location.href = redi;
                localStorage.removeItem("redirectAfterlogin");
                return;
              }

              switch (user?.role) { 

                default:
                  // redirection à l'accueil
                  history.push("/");
                  break;
              }
            }) 
        }else{

        const redi = localStorage.getItem("redirectAfterlogin");
        if (redi) {
          window.location.href = redi;
          localStorage.removeItem("redirectAfterlogin");
          return;
        }

        switch (user?.role) { 

          default:
            // redirection à l'accueil
            history.push("/");
            break;
        }
        }
        setLoading(false);
      })
      
    }else{ 
      
    const redi = localStorage.getItem("redirectAfterlogin");
    if (redi) {
      window.location.href = redi;
      localStorage.removeItem("redirectAfterlogin");
      return;
    }

    switch (user?.role) { 

      default:
        // redirection à l'accueil
        history.push("/");
        break;
    }
    }


  };

  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        console.log("error", error);
        cb(null);
      });
  };

  useEffect(() => {
    // check if user is login
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          firebase.auth().languageCode = "fr";
          redirection(user);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
      setTimeout(() => {
        setAlertLogout(false);
        setAlertDelete(false);
        setForgetPass(false);
      }, 5000);
    }, 5000);
  }, []);

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const getPanier = async (uid, cb) => {
    await firebase.firestore()
      .collection("carts")
      .doc(uid)
      .get()
      .then(async (docd) => {
        if (docd.exists){
          var cart = docd.data();
          cart.id = docd.id;
          cb(cart);
        }else{
          cb(null)
        }
      })
      .catch((error) => {
        console.log("getPanier error", error);
        cb(null);
      });

  }

  const logIn = (value) => { 
    if (loading) {
      return;
    }
    setLoading(true);
    firebase.auth()
      .signInWithEmailAndPassword(value.mail.trim(), value.password.trim())
      .then(async (res) => { 
        //redirection automatique
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //do something with the error
        console.log(err.code);
        // alert(getTranslation(err.code));
        alert("Identifiant invalide");
      });
  };

  return (
    <>
      <div
        class="tab-content"
        style={{ width: 300, paddingTop: "5%", paddingBottom: "5%" }}
      >
        {loadingUser ? (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            <p style={{ textAlign: "center" }}>Chargement ... </p>
          </div>
        ) : (
          <div
            class="tab-pane fade show active"
            id="pills-login"
            role="tabpanel"
            aria-labelledby="tab-login"
          >
            {logout && <Alert>Déconnexion réussi</Alert>}
            {deleteU && <Alert>Compte supprimé</Alert>}
            {forgetPass && (
              <Alert>
                Lien de réinitialisation de mot de passe envoyé par email.
                vérifiez vos spams.
              </Alert>
            )}
            <form
              onSubmit={handleSubmit(logIn, (err) => {
                console.log(err);
              })}
            >
              <h2 class="text-center p-4">Connexion</h2>

              <div class="form-outline mb-4">
                <label class="form-label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  required
                  onChange={(e) => setValue("mail", e.target.value)}
                  {...register("mail")}
                />
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="password">
                  Mot de passe
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  class="form-control"
                  required
                  onChange={(e) => setValue("password", e.target.value)}
                  {...register("password")}
                />
              </div>
              <div class="row mb-4">
                <div class="col-md-7 d-flex justify-content-left">
                  <a href="/forgetpass">Mot de passe oublié?</a>
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-dark btn-block mb-4"
                disabled={loading}
              >
                {loading ? "Chargement ... " : "Connexion"}
              </button>
            </form>

            <div class="text-center">
              <p>
                Pas de compte ? <a href="/inscription">S'inscrire</a>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const Login = ({ navigation }) => (
  <div class="container  p-4 d-flex justify-content-center">
    <Action3 />
  </div>
);
export default Login;
