import './App.css';
import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Layout from './components/Layout';
import Home from './pages/home';
import Contact from './pages/contact';
import Login from './pages/login';
import ForgetPass from './pages/ForgetPass';
import Register from './pages/Register';
import Cgv from './pages/cgv';
import About from './pages/about';
import Orders from './pages/orders';
import Categories from './pages/categories';
import Product from './pages/products';
import Panier from './pages/panier';
import Load from './pages/load';
import {Provider}  from 'react-redux'
import {createStore, combineReducers} from 'redux'
import ReduxToastr from 'react-redux-toastr'
import {reducer as toastrReducer} from 'react-redux-toastr'
import Order from './pages/order';
import MyAccount from './pages/myaccount';
import MentionsLegale from './pages/legales';
import ProductsAdmin from './pages/productsAdmin';
import Edit from './pages/edit';
import ClientsAdmin from './pages/clientAdmin';
import CodePromoAdmin from './pages/codePromoAdmin';
import CodePromoAdminNew from './pages/codePromoAdminNew';
import CodePromoAdminEdit from './pages/codePromoAdminEdit';
const reducers = { 
  toastr: toastrReducer  
}
const reducer = combineReducers(reducers)
const store = createStore(reducer)




function App() {

  const history = useHistory();

  React.useEffect(() => {
    history.listen((location, action) => {
      if (action === "PUSH" || action === "REPLACE" || action === "POP") {
        window.scrollTo(0, 0);
      }
    });
  }, []);
  
  return (
    <div className="App"> 
     <Provider store={store}>
      <Layout>
      <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/c/:cat" exact>
            <Categories />
          </Route>
          <Route path="/p/:id/:idp" exact>
            <Product />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/connexion" exact>
            <Login />
          </Route>
          <Route path="/cgv" exact>
            <Cgv />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route> 
          </Switch>
          <Route path="/forgetpass" exact>
            <ForgetPass />
          </Route>
          <Route path="/inscription" exact>
            <Register />
          </Route>
          <Route path="/load" exact>
            <Load />
          </Route>
          <Route path="/panier" exact>
            <Panier />
          </Route>

          <Route path="/commandes" exact>
            <Orders />
          </Route>

          <Route path="/order/:id" exact>
            <Order />
          </Route>


          <Route path="/moncompte" exact>
            <MyAccount />
          </Route>

          <Route path="/mentionslegales" exact>
            <MentionsLegale />
          </Route>

          <Route path="/admin" exact>
            <Orders isadmin={true} />
          </Route>

          <Route path="/admin/products" exact>
            <ProductsAdmin />
          </Route>

          <Route path="/admin/clients" exact>
            <ClientsAdmin />
          </Route>
          <Route path="/admin/codepromo" exact>
            <CodePromoAdmin />
          </Route>
          <Route path="/admin/codepromo/new" exact>
            <CodePromoAdminNew />
          </Route>

          <Route path="/admin/codepromo/edit/:id" exact>
            <CodePromoAdminEdit />
          </Route>
          

          

          <Route path="/edit/:id/:idp" exact>
            <Edit />
          </Route>
          
          
      </Layout>
      <ReduxToastr
      timeOut={1500}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      getState={(state) => state.toastr} // This is the default
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick/>
</Provider>
    </div>
  );
}

export default App;
