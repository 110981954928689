import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toastr } from 'react-redux-toastr'; 
import { API_URL } from "../constant";




export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [barloading, setBarloading] = React.useState(0);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [orderLoading, setOrderloading] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderId, setOrderId] = useState(null);

  // const timer = ms => new Promise(res => setTimeout(res, ms))
      
  const goToOrderPage = async () => {
    setOrderloading(true);
    // setBarloading(10)
    // let p = 0
    // for(let i = 0; i < 9 ; i++) {
    //   p += 10
    //   if (p > 90){
    //     p = 95
    //   }
    //   await timer(1000);
    //   setBarloading(p)
    // }
    const payment_intent = new URLSearchParams(window.location.search).get(
        "payment_intent"
      );
      fetch(`${API_URL}after-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ payment_intent }),
      })
        .then((res) => res.json())
        .then((data) => {
            // console.log("data", data);
            if (data?.orderNumber){
                setOrderNumber(data.orderNumber);
                setOrderId(data.orderId);
                // setBarloading(100)
            }
            setOrderloading(false);
        });

  }
 
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    setCheckLoading(true);
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Paiement réussi !");
          goToOrderPage();
          toastr.success('', 'Paiement réussi !');
          break;
        case "processing":
          setMessage("Votre paiement est en cours de traitement.");
          toastr.success('', 'Votre paiement est en cours de traitement.');
          break;
        case "requires_payment_method":
          setTimeout(() => {
            setCheckLoading(true)
          }, 1000);
          setMessage("Votre paiement n'a pas abouti, veuillez réessayer.");
          toastr.error('', "Votre paiement n'a pas abouti, veuillez réessayer.");
          break;
        default:
          setMessage("Quelque chose s'est mal passé");
          toastr.error('', "Quelque chose s'est mal passé");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/panier`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
        {!checkLoading && (
            <>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit"  class="btn btn-lg btn-success" style={{marginTop:20}}>
                    <span id="button-text" >
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Payer maintenant"}
                    </span>
                </button>
            </>
        )}
      {orderNumber && (
        <>
          <h5>Numéro de commande : {orderNumber}</h5>
          <p><a href={`/order/${orderId}`}>Voir le detail de la commande</a></p>
        </>
      )}
      {orderLoading && <>
        <p>Traitement de la commande... (Vous pouvez fermer la page)</p></>}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
      {message && message === "Votre paiement n'a pas abouti, veuillez réessayer." && (
        <p><a href="/panier">Réessayer</a></p>
      )}
    </form>
  );
}