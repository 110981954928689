import React , { useEffect, useState } from "react"; 
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { useHistory } from "react-router-dom";

const ProductsAdmin = () => { 

    const db = firebase.firestore();
    const history = useHistory();

    const [loadingUser, setLoadingUser] = React.useState(false);
    
  const [l, setL] = useState(false)

  const [products, setData] = useState([]);
    const [user, setUser] = React.useState(null);


    const getUser = async (uid, cb) => {
        await firebase.firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then(async (docd) => {
            var user = docd.data();
            user.id = docd.id;
            cb(user);
          })
          .catch((error) => {
            cb(null);
          });
      };

    const getProducts = async () => { 
        await firebase.firestore()
        .collection("products")  
        .get()
        .then(async (snapshots) => {
            let data = [];
            snapshots.forEach(d => {
                var ds = d.data();
                ds.id = d.id;
                data.push(ds);
            })
            if (data.length > 0){
                setData(data);
                setL(false);
            }
        }).catch(() => {
            setL(false);
        })
    }

  useEffect(()=>{
    setL(true)
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        setLoadingUser(true);   
          getUser(u.uid, async (user) =>  { 
            setUser(user);
            firebase.auth().languageCode = "fr";  
            if (user.isAdmin === true){ 
               getProducts()
               setLoadingUser(false);
            }else{
              history.push("/");
              toastr.error("Vous n'avez pas le droit d'accès a cette page.")
            }
          });
        
        
      } else {
        setLoadingUser(false);
        history.push("/");
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);

  }, [])

  const getMinPrice = (p) =>  { 
    let min = Number(p?.price);
    if (p?.pricePer){
      p?.pricePer?.forEach((e)=>{
        if (e?.price < min){
          min = e?.price;
        }
      })
    }
    if (p?.packs?.length > 0){
      p?.packs.forEach((a, i)=>{
        if (a?.price < min){
          min = a?.price;
        }
      })
    } 
    return min;
  }

return ( 
    <>
    <div class="container">
    <h1 class="mt-5">{"Administration des produits"}</h1> 
    <div class="row mb-5" style={{textAlign:"left"}}>
    {l ? (
        <p>Chargement ...</p>
    ): (
<div class="table-responsive">
        <table class="table table-striped table-sm">
                        {products?.length > 0 && (
                          <thead>
                            <tr>
                              <th scope="col">#</th> 
                              <th scope="col">Image</th>
                              <th scope="col">Categories</th> 
                              <th scope="col">Nom</th>
                              <th scope="col">A partir de</th> 
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                        )} 
                        <tbody> 
                        {
                            products?.map((p) => {
                                return (
                                    <>
                                    {p?.products?.map(ps => {
                                        return (<tr>
                                            <td>#{p.id}-{ps.id}</td>
                                            <td><img src={ps.image} style={{height: 40, margin: 'auto', display:'flex'}} /></td>
                                            <td>{p?.title ||p.category }</td> 
                                            <td>{ps.title}</td>
                                            <td>{(getMinPrice(ps) / 100).toFixed(2)} €</td> 
                                            <td> 
                                                <a
                                                href={`/p/${p.id}/${ps.id}`} 
                                                >
                                                    <i class="fa fa-eye"></i>
                                                </a>
                                                <a
                                                href={`/edit/${p.id}/${ps.id}`} style={{marginLeft:10}}
                                                >
                                                    <i class="fa fa-edit"></i>
                                                </a>
                                            </td>
                                            {console.log("ps", ps)}
                                        </tr>)
                                    })
                                        
                                        
                                    }
                                    </>
                                )
                            })
                        }
                          
                          {/* {myOrders.map((order) => { 
                            return (<tr>
                              <td>#{order.orderNumber}</td>
                              <td>{moment(order.order_date.toDate()).format("DD MMM YYYY")}</td>
                              <td>
                                {order.livraison ? "Livraison" : "a récupérer"}
                                {order.livraison && order.deliveryNumber && (<>
                                  <br />
                                <a target="_blank" href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${order.deliveryNumber}`}>{order.deliveryNumber}</a></>)}
                              </td>
                              <td style={{ color: getstatus(order)?.color || "black" }}>
                                {getstatus(order)?.name}
                              </td>
                              <td>{order?.amount ? `${Number((order.amount / 100)).toFixed(2)} €`:  "erreur"}</td>
                              <td>
                                <a
                                  href={`/order/${order.id}`} 
                                >
                                  <i class="fa fa-eye"></i>
                                </a>
                              </td>
                            </tr>)
                          })}  */}
                        </tbody>
                      </table>
                    </div>
    )}
    
    
     


       </div>
  </div>
  </>
  )};
  export default ProductsAdmin;
  