
import '../App.css';  

const Contact = () => { 
  return (
    <>
    <div class="container">
    <h1 class="mt-5">Contact</h1> 
    <div class="row mb-5">
     
      <div class="col-12 col-md-4 color-primary">Téléphone</div>
      <div class="col-12 col-md-8">
        <a href="tel:0692674905" class="unselectable">
        0692674905
        </a>
      </div>
      <div class="col-12 col-md-4 color-primary">E-Mail</div>
      <div class="col-12 col-md-8">
        <a href="mailto:contact@personnaliz.re" class="unselectable">
          contact@personnaliz.re
        </a>
      </div>
   
    </div> 
  </div>
  </>
);
  }
export default Contact;
