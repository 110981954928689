import React , { useEffect, useState } from "react"; 
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { Badge } from "react-bootstrap";
import { useHistory,useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const CodePromoAdminEdit = () => { 

    const { id } = useParams();
    const db = firebase.firestore();
    const history = useHistory(); 
    const [user, setUser] = React.useState(null); 
    const [loadingUser, setLoadingUser] = React.useState(false);
    const [l, setL] = useState(false)
    const [loadingForm1, setLoadingF1] = useState(false)
  const [code, setCode] = useState(false)
    const [formData, setFormData] = useState({});
    const mp =new Date()
    const year = mp.getFullYear(); 
    
    const month = String(mp.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = String(mp.getDate()).padStart(2, '0'); 
    const [dateend, setDateEnd] = React.useState(`${year}-${month}-${day}`);
    const [datearrive, setDateArrive] = React.useState(`${year}-${month}-${day}`);
    
     
    const { register, handleSubmit, setValue } = useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: false,
      shouldUseNativeValidation: false,
      delayError: undefined,
    });
  
      const getCode = async () =>   {
        setL(true);
        await firebase.firestore()
          .collection("codes") 
          .doc(id)
          .get()
          .then(async (d) => {
            var ds = d.data();
            ds.id = d.id; 
            setCode(ds)
            setValue("namecode", ds.namecode)
            setValue("code", ds.code)
            setValue("reduction", ds.reduc)
            setValue("percent", ds.percent)
            console.log('dateend', )
            console.log('datestart', ds?.datestart)



            const mp = ds?.dateend?.toDate()
            const year = mp.getFullYear();
            const month = String(mp.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
            const day = String(mp.getDate()).padStart(2, '0'); 
            
            setDateEnd(`${year}-${month}-${day}`)
    

            const mp2 = ds?.datestart?.toDate()
            const year2 = mp2.getFullYear();
            const month2 = String(mp2.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
            const day2 = String(mp2.getDate()).padStart(2, '0'); 
            
            setDateArrive(`${year2}-${month2}-${day2}`)


            setL(false);
          })
          .catch((error) => {
            setL(false);
            console.log("error", error);   
          });
      }

      const getUser = async (uid, cb) => {
        await db
          .collection("users")
          .doc(uid)
          .get()
          .then(async (docd) => {
            var user = docd.data();
            user.id = docd.id;
            cb(user);
          })
          .catch((error) => {
            cb(null);
          });
          
      };
      const updateUserForm = (value) => {
        // value.preventDefault()
        // formulaire niveau1 vendeur
        if (loadingForm1) {
          return;
        }
        console.log("namecode", value.namecode.trim())
        const namecode = value.namecode.trim();
        console.log("code", value.code.trim())
        const code = value.code.trim();
        console.log("datestart", datearrive)
        const datestart = datearrive;
        console.log("dateend", dateend) 
        console.log("reduction", value?.reduction )
        const reduc = Number(value?.reduction );
        console.log("percent", value?.percent )
        const percent = value?.percent;
 
        if (namecode?.length === 0){
            toastr.error("Le champs Nom (titre) est requis")
            return;
        }

        if (code?.length === 0){
            toastr.error("Le champscode est requis et nécessite 5 caractères minimum")
            return;
        }

        if (datestart?.length === 0){
            toastr.error("Le champs date de départ est requis ")
            return;
        }
        if (dateend?.length === 0){
            toastr.error("Le champs date de cloture est requis ")
            return;
        }
        if (reduc === 0){
            toastr.error("Le champs réduction est requis")
            return;
        }
        setL(true)

        db.collection("codes").doc(id)
        .update({ 
            namecode: namecode,
            code: code,
            datestart: new Date(datestart),
            dateend: new Date(dateend),
            reduc: reduc,
            percent: percent,   
            _update_At: new Date(),
        })
        .then(async () => {
            toastr.success('Code modifié')
            setL(false)
            history.push("/admin/codepromo");
        }).catch(err => {
            toastr.error('Erreur', err)
            setL(false)
        })

    }
      
      useEffect(()=>{
        setL(true)
        setLoadingUser(true);
        firebase.auth().onAuthStateChanged((u) => {
          if (u) { 
            setLoadingUser(true);  
            getCode() 
              getUser(u.uid, async (user) =>  { 
                setUser(user);
                firebase.auth().languageCode = "fr";  
                if (user.isAdmin === true){  
                   setLoadingUser(false);
                   setL(false)
                }else{
                  history.push("/");
                  toastr.error("Vous n'avez pas le droit d'accès a cette page.")
                }
              });
            
            
          } else {
            setLoadingUser(false);
            history.push("/");
          }
        });
        setTimeout(() => {
          setLoadingUser(false);
        }, 5000);
    
      }, [])

    return ( 
        <>
        <div class="container">
        <h1 class="mt-5">Edition de code promotionnelles</h1> 
        {loadingUser ? <p>Chargement ...</p> : (
            <div class="row mb-5" style={{textAlign:"left"}}> 
             
         <Form class="needs-validation"
        novalidate 
        onSubmit={handleSubmit(updateUserForm, (err) => {
          console.log(err);
        })}>
            <Form.Group className="mb-3" controlId="namecode">
            <Form.Label htmlFor="namecode">Nom du code</Form.Label>
                        <Form.Control
                            type="text"
                            id="namecode" 
                            name="namecode"
                            {...register("namecode")}
                            required 
                        />
            </Form.Group>
            <Form.Group className="mb-3" controlId="code">
            <Form.Label htmlFor="code">CODE</Form.Label>
               <Form.Control type="text" id="code"  name="code"
                            {...register("code")} required
                        />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="datestart">
                <Form.Label htmlFor="datestart">Date de départ</Form.Label>
                <Form.Control value={datearrive}  
                            required name="datestart" id="datestart" type="date" onChange={e => {
                            // 
                            // console.log("date", e?.target?.valueAsDate) 
                           let date = e?.target?.valueAsDate
                            const year = date.getFullYear();
                            const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
                            const day = String(date.getDate()).padStart(2, '0');

                        const formattedDate = `${year}-${month}-${day}`;
                        setDateArrive(formattedDate);
                        // console.log('date', formattedDate)
                            setFormData({ ...formData, start: formattedDate });
                        }}   />
            </Form.Group>


            <Form.Group className="mb-3" controlId="dateend">
                <Form.Label htmlFor="dateend">Date de cloture</Form.Label>
                <Form.Control value={dateend} 
                            required name="dateend" min={datearrive} id="dateend" type="date" onChange={e => {

                        let date = e?.target?.valueAsDate
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
                        const day = String(date.getDate()).padStart(2, '0');

                        const formattedDate = `${year}-${month}-${day}`;
                            setDateEnd(formattedDate);
                            setFormData({ ...formData, end: formattedDate });
                        }}   />
            </Form.Group>
            <Form.Group className="mb-3" controlId="reduction">
            <Form.Label htmlFor="reduction">Réduction (€ | %)</Form.Label>
                        <Form.Control
                            type="number"
                            id="reduction" 
                            name="reduction"
                            {...register("reduction")}
                            required
                        />
                        <Form.Check type="checkbox" 
                            id="percent" 
                            name="percent" label="En pourcentage" 
                            {...register("percent")} />

            
            </Form.Group> 

            <Form.Group className="mb-3" controlId="reduction">
                        <Button variant="primary" type="submit" disabled={l}>
                {l ? 'Chargement' : 'Modifier'}
            </Button>
                            </Form.Group>
        </Form>  
 
        </div> 
        )}
        
    </div>
    </>
    )};
  export default CodePromoAdminEdit;
  