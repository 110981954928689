import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import Header from "../components/Header"; 
import HeaderLogin from '../components/HeaderLogin';
import Image from 'react-bootstrap/Image';
     

const Home = () =>{
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [isLog, setIsLog] = React.useState(false);
  const [user, setUser] = React.useState(null);


  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };


  useEffect(() => {
    // check if user is login
    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        setLoadingUser(true);
        //redirection au compte
        getUser(u.uid, (user) => {
          // console.log("user", user);
          setUser(user);
          firebase.auth().languageCode = "fr"; 
          setLoadingUser(false);
          setIsLog(true);
        });
      } else {
        setLoadingUser(false);
      }
    });
    setTimeout(() => {
      setLoadingUser(false); 
    }, 5000);
  }, []);
  return (
    <> 
    <Header />
    <div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3 ">
        <a href="/c/tirages" className="nounderline"> 
        <div class="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
          <div class="my-3 py-3">
            <h2 class="display-5">Tirage photo</h2>
            <p class="lead">Imprimez vos souvenirs illico en format classique.</p>
          </div>
          <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 160, borderRadius: "21px 21px 0 0"}}>
  
          <Image src="https://www.myposter.fr/magazin/wp-content/uploads/2022/02/combien-coute-un-tirage-photo-9x13-cm.jpg" style={{borderRadius: "21px 21px 0 0"}} fluid />;
          
          </div>
        </div>
        </a>
        <a href="/c/poster" className="nounderline"> 
        <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div class="my-3 p-3">
            <h2 class="display-5">Poster</h2>
            <p class="lead">Parce qu'il y a des moments inoubliables.</p>
          </div>
          <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 180, borderRadius: "21px 21px 0 0"}}>
  
          <Image src="https://www.printimmo.com/documents/images/general_photos/impression-affiches-suspendues-20.jpg" style={{borderRadius: "21px 21px 0 0"}} fluid />;
  
          </div>
        </div>
        </a>
      </div>
  
      <div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
      <a href="/c/magnet-aluminium" className="nounderline"> 
        <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div class="my-3 p-3">
            <h2 class="display-5">Photo magnétique</h2>
            <p class="lead">Imprimez vos magnets personnalisées à coller sur le frigo.</p>
          </div>
          <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 200, borderRadius: "21px 21px 0 0"}}>
  
          <Image src="https://static.photoweb.fr/photoweb.web.catalog.frontoffice/Catalogue/tirages-photo/la-boite-a-magnets/carrousel/1-la-boite-a-magnets.jpg" style={{borderRadius: "21px 21px 0 0"}} fluid />;
          </div>
        </div>
        </a>
        <a href="/c/photo-sur-toile" className="nounderline"> 
        <div class="bg-primary mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
          <div class="my-3 py-3">
            <h2 class="display-5">Photo sur toile</h2>
            <p class="lead">Embellissez vos photos avec un cadre moderne.</p>
          </div>
          <div class="bg-light box-shadow mx-auto" style={{width: "80%", height: size.width > 800 ? 300 : 200, borderRadius: "21px 21px 0 0"}}>
  
          <Image src="https://smash-images.photobox.com/optimised/828a3e59d5647a8a8f2cda236434b979551831c5_file_desktop_photobox_simple_canvas_desktop-@1x.jpg" style={{borderRadius: "21px 21px 0 0"}} fluid />;
          </div>
        </div>
        </a>
      </div>
      {!isLog && !loadingUser && (<HeaderLogin />)}
    </>
  );
};


export default Home;
