import React, { useEffect, useState } from "react";
import firebase from "../firebase"; 
import { toastr } from 'react-redux-toastr'; 
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useForm } from "react-hook-form";

const Orders = ({isadmin = false}) => { 
  const [myOrders, setMyOrders] = useState([]);
  const [configlivraison, setCL] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const db = firebase.firestore();
  const history = useHistory();

  const [loadingForm1, setLoadingF1] = useState(false)
  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const getConfig = async ( ) => {
    await db
      .collection("config")
      .doc('livraisons')
      .get()
      .then(async (docd) => {
        var livraison = docd.data();
        livraison.id = docd.id;
        setCL(livraison);
        setValue("price", Number(livraison.price/100).toFixed(2) || 0);
      }) 
  };


  const getOrders = async (uid, cb) => {
    let orders =[]
    await db
      .collection("orders") 
      .where("userId", "==", uid)
      .orderBy("order_date", 'desc')
      .get()
      .then(async (querySnapshot) => { 
        querySnapshot.forEach((doc) => {
          var order = doc.data();
          order.id = doc.id;
          orders.push(order)
        })
        cb(orders)
      })
      .catch((error) => {
        console.log("getOrders error", error);
        
        cb(orders)
      });

  }
      
  
  const getUser = async (uid, cb) => {
    await firebase.firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then(async (docd) => {
        var user = docd.data();
        user.id = docd.id;
        cb(user);
      })
      .catch((error) => {
        cb(null);
      });
  };


  useEffect(()=>{

    setLoadingUser(true);
    firebase.auth().onAuthStateChanged((u) => {
      if (u) { 
        setLoadingUser(true); 
        if (isadmin){
          getConfig()
          getUser(u.uid, async (user) =>  {
            // console.log("user", user);
            setUser(user);
            firebase.auth().languageCode = "fr";  
            if (user.isAdmin === true){
              let orders =[]
              await db
              .collection("orders")  
              .orderBy("order_date", 'desc')
              .get()
              .then(async (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  var order = doc.data();
                  order.id = doc.id;
                  orders.push(order)
                })
                setMyOrders(orders);
              })
              .catch((error) => { 
                setMyOrders(orders);
              });
            }else{
              history.push("/");
              toastr.error("Vous n'avez pas le droit d'accès a cette page.")
            }
          });
        }else{
          getOrders(u.uid,  orders => {
            if (orders && orders.length > 0){
                console.log("orders", orders);
                setMyOrders(orders);

            }else{  
                // alert("Aucun produit au panier"); 
                toastr.error('Vous avez effectué aucune commande');
            }
            setLoadingUser(false);
        })
        }
        
      } else {
        setLoadingUser(false);
        history.push("/");
      }
    });
    setTimeout(() => {
      setLoadingUser(false);
    }, 5000);

  }, [])

  function getstatus(o) {
    let v = {
      color: "black",
      name: "Chargement ..",
    };

    switch (o?.orderstatus){
      case "finished":
        v = {
          color: "green",
          name: "Terminé",
        };
        break;
      case "inprogress":
        v = {
          color: "orange",
          name: "En cours de traitement",
        };
        break;
        default:
          v = {
            color: "red",
            name: "Annulé",
          }
          break;
    }
    return v;
  }

  const updateUserForm = (value) => {
    // formulaire niveau1 vendeur
    if (loadingForm1) {
      return;
    }
    let k = Number(value?.price?.trim().replace(",",".")*100)
     
    setLoadingF1(true);
    db.collection("config")
      .doc("livraisons")
      .update({
        price: k,
      }).then(async () => {
        toastr.success("Données de livraison mis à jour!");
        setLoadingF1(false);
      }).catch((err) => {
        setLoadingF1(false);
        toastr.error("Une erreur s'est produite!");
        console.log("updateVendorForm", err);
      });
  }

return ( 
    <>
    <div class="container">
    <h1 class="mt-5">{isadmin ? "Administration des commandes": 'Mes commandes'}</h1> 
    <div class="row mb-5" style={{textAlign:"left"}}>
    {isadmin && configlivraison && (
      <>
        <h2>Module de configuration de livraison (tarif)</h2>
        <p>Le tarif actuel de livraison est de {Number(configlivraison?.price/100).toFixed(2)}€</p>
        <form
        class="needs-validation"
        novalidate 
        onSubmit={handleSubmit(updateUserForm, (err) => {
          console.log(err);
        })}>
         <div class="row g-3">
            <div class="col-sm-3">
                                <label for="price" class="form-label">
                                Prix
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="price"
                                  name="price"
                                  placeholder="Prix de livraison"
                                  required
                                  onChange={(e) =>
                                    setValue("price", e.target.value)
                                  }
                                  {...register("price")}
                                />
                                <div class="invalid-feedback">
                                  Prix est requis
                                </div>


                              
                              
                            <button
                              class="mt-3 btn btn-dark btn-sl"
                              type="submit"
                              disabled={loadingForm1}
                            >
                              {loadingForm1
                                ? "Chargement ...."
                                : "Enregistrer et mettre à jour le tarif"}
                            </button>
                              
                              </div>


          </div>
                              
                              
                          </form>
      </>
    )}
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
   
      <h1 class="h2">{isadmin ? "Commandes": 'Vos Commandes'} ({loadingUser ? "Chargement ...": myOrders?.length})</h1> 
                    </div> 
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        {myOrders?.length > 0 && (
                          <thead>
                            <tr>
                              <th scope="col">#Commande</th>
                              <th scope="col">Date</th>
                              <th scope="col">Mode de livraison</th>
                              <th scope="col">État</th>
                              <th scope="col">Total</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                        )} 
                        <tbody> 

                          
                          {myOrders.map((order) => { 
                            return (<tr>
                              <td>#{order.orderNumber}</td>
                              <td>{moment(order.order_date.toDate()).format("DD MMM YYYY")}</td>
                              <td>
                                {order.livraison ? "Livraison" : "a récupérer"}
                                {order.livraison && order.deliveryNumber && (<>
                                  <br />
                                <a target="_blank" href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${order.deliveryNumber}`}>{order.deliveryNumber}</a></>)}
                              </td>
                              <td style={{ color: getstatus(order)?.color || "black" }}>
                                {getstatus(order)?.name}
                              </td>
                              <td>{order?.amount ? `${Number((order.amount / 100)).toFixed(2)} €`:  "erreur"}</td>
                              <td>
                                <a
                                  href={`/order/${order.id}`} 
                                >
                                  <i class="fa fa-eye"></i>
                                </a>
                              </td>
                            </tr>)
                          })}

                          {/* <tr>
                            <td>#0003 CardShop</td>
                            <td>28 Avril 2022</td>
                            <td>
                              Lettre + suivis
                              <br />
                              <a href="#">LB456789387839</a>
                            </td>
                            <td style={{ color: "green" }}>Livré</td>
                            <td>8,50€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  // sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>


                          <tr>
                            <td>#0999 Patrick Lye</td>
                            <td>27 Avril 2022</td>
                            <td>Main propre</td>
                            <td>Payé</td>
                            <td>18,30€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  // sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Badge bg="danger">1</Badge> #0998 John Doe
                            </td>
                            <td>26 Avril 2022</td>
                            <td>--</td>
                            <td>Annulé</td>
                            <td>48,50€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  // sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td> #0997 John Doe</td>
                            <td>26 Avril 2022</td>
                            <td>--</td>
                            <td>
                              Remboursé
                              <br />
                              <span
                                style={{ fontSize: 12, fontWeight: "10px" }}
                              >
                                Partiellement <a href="#">10,20€</a>
                              </span>
                            </td>
                            <td>12,50€</td>
                            <td>
                              <a
                                href="#your-order"
                                onClick={() => {
                                  // sYOrder(1); // set data to show
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>

              </div>



       
  </div>
  </>
  )};
  export default Orders;
  